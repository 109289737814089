import React from 'react';
import MidiHelper from '../../../services/midi/MidiHelper';


const trackHeaders = [
    'track_num',
    'track_start',
    'track_end',
    'track_duration',
    'note_count',
    'inst_name',
    'inst_num',
];


/**
 * Summarizes the main midi information.
 * Information above the track level stored in header.
 */
export default class TrackSummaryTable extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            midi: props.midi
        }
    }

    /**
     * 
     * @param {*} track 
     */
    getTrackRowArray(track) {
        const {notes,pitchBends,instrument,controlChanges} = track;
        const {number,family, name, percussion} = instrument;

    }

    //https://github.com/Tonejs/Midi
    render() {
        const {midi} = this.state;

        const {header, tracks: originalTracks} = midi;
        const {ppq, tempos, timeSignatures} = header;

        let tracks = originalTracks.filter(track => {
            const {notes} = track;

            if (!notes || notes.length === 0) {
                return false;
            }

            return true;

        })

        const rows = tracks.map((track, i) => {

            const key = `track-${i}`;

            const {notes,pitchBends,instrument,controlChanges} = track;
            const {number,family, name, percussion} = instrument;

            let notesLength = 0;
            let start = 0;
            let stop = 0;
            let duration = 0;
            if (notes && notes.length > 0) {
                notesLength = notes.length;
                const start = notes[0];
                const stop = notes[notes.length - 1];
                console.log({start,stop})
            }

            return (
                <tr key={key}>

                <td>{name} #{number}</td>
                <td>{notes.length}</td>
                <td>{start}</td>
                <td>{stop}</td>
                <td>{duration}</td>

            </tr>
            )
        }) 

        return (
            <div>
                <table className='russ-table'>
                    <thead>
                        <tr>
                        <th>
                            instrument
                        </th>
                        <th>
                            note count
                        </th>
                        <th>
                            start
                        </th>
                        <th>
                            stop
                        </th>
                        <th>
                            duration
                        </th>
                        </tr>

                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
        
            </div>
        )
    }
}