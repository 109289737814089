import React from 'react';


export default class BaseComponent extends React.Component {


    
    constructor(props) {
        super(props);

        this.state = {

        }

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    render() {

        return (
            <div>
            </div>
        )
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    // https://github.com/facebook/react/issues/10135
    async componentDidMount( prevProps,  prevState) {

    }
}