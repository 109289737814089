// https://gibber.cc/alpha/playground/
// https://github.com/audiojs/web-audio-api

// c++
// https://github.com/LabSound/LabSound



// https://www.npmjs.com/package/web-audio-api
// https://www.npmjs.com/package/speaker

const notes = require('./notes');

const additionalRowColumnParsing = (colStr) => {
    const colArray = colStr.split(' ').filter(col => !!col);
    return colArray;
}

const parseRowStr = (rowStr) => {
    const rowAry = rowStr.split(':');

    const rowKey = rowAry[0].split(' ')[1];
    const rowIndex = parseInt(rowKey,16);

    let columns = rowAry.slice(1);

    columns = columns.map(additionalRowColumnParsing);

    return {
        rowIndex,
        rowKey,
        columns
    }
}


const parsePatterns = (json) => {

    let patterns = [];

    let i = 0;
     
    while ( true) {
        // console.log({i}, json.tracks.length)
        if (i >= json.tracks.length) {
            break;
        }
        let str = json.tracks[i];
        // console.log(i, str);

        // found pattern populate until out of rows.
        // PATTERN 00
        if (str.indexOf('PATTERN') == 0) {
            const patternKey = str.split(' ')[1];
            const patternObj = {
                patternKey,
                rows: []
            }
            i++;
            const rows = [];
            while( true) {
                if (i === json.tracks.length) {
                    // parse rows
                    patternObj.rows = rows.map(parseRowStr);
                    patterns.push(patternObj);
                    break;
                } else {
                    str = json.tracks[i];
                    if (str.indexOf('ROW') === 0) {
                        // console.log('push rows',str);
                        rows.push(str);
                    } else {
                        // parse rows
                        patternObj.rows = rows.map(parseRowStr);
                        patterns.push(patternObj);
                        // step back one before returning to main loop.
                        i--;
                        break;
                    }
                }
                i++;

            }
            // const patternKey = row.split(' ')[1];
            
        }
        i++;

    }

    return patterns;
}

const parseOrders = (json) => {

    let orders = [];

    let i = 0;
     
    while ( true) {
        if (i === json.tracks.length) {
            break;
        }
        let str = json.tracks[i];
        if (str.indexOf('ORDER') === 0) {
            orders.push(str);
        }
        i++;


    }

    orders = orders.map(order => {
        const ary = order.split(' ').filter(item => !!item && item !== ':');
        const key = ary.shift();

        return {
            key,
            trackPatterns: ary
        }

        // return ary;
    })

    return orders;
}

const parseTracks = (jsonData) => {
    const patterns = parsePatterns(jsonData);
    const orders = parseOrders(jsonData);

    return {
        orders,
        patterns
    }

}

/**
 * Maybe just converting it to a midi format here makes sense
 * expanded will just be 4 tracks.
 * @param {*} jsonData 
 */
const getExpandedTrack = (jsonData) => {
    const {orders,tracks,patterns} = jsonData;
    let expanded = [];

    orders.forEach(order => {
        const {key,trackPatterns} = order;
        console.log({key,trackPatterns});

        // so I think for the orders it is each column corresponds to another patterns column?
        // so different patterns can be combined together?
        const trackPattern = trackPatterns[0];

        const pattern = patterns.find(pattern => pattern.patternKey === trackPattern);
        if (pattern) {
            pattern.rows.forEach(row => {
                expanded = [...expanded,...row.columns];
            })
        } else {
            console.log(`failed to find pattern ${trackPattern}`, patterns);
        }



    });

    return expanded;
    
}

const famitrackerToJson = (dataString) => {


    let stringArray = dataString.split('\n');
    stringArray = stringArray.map(val => val.trim());
    // console.log(stringArray);

    let jsonData = {
        stringArray,
    };

    let header;
    for (let str of stringArray) {
        if (str === '') {
            continue;
            // header = '';
        }
        if (str.substr(0,1) === '#') {
            console.log('found header');
            console.log(str);
            if (str === '# FamiTracker text export 0.4.2') {
                jsonData.version = '0.4.2';
            }
            if (str === '# Song information') {
                header = 'songInfo'
                jsonData[header] = {};
            } else if (str === '# Global settings') {
                header = 'globalSettings'
                jsonData[header] = {};
            } else if (str === '# Song comment') {
                header = 'songComment'
                jsonData[header] = {};
            } else if (str === '# Macros') {
                header = 'macros'
                jsonData[header] = {};
            } else if (str === '# Instruments') {
                header = 'instruments'
                jsonData[header] = {};
            } else if (str === '# Tracks') {
                header = 'tracks'
                jsonData[header] = [];
            }
        } else if (header) {
            // TODO https://chevrotain.io/docs/ lexer?
            // console.log('process header data ',header,str);
            const data = str.split(' ');
            let key,val;
            if (header === 'instruments') {
                ([key,val] = [data[0],data.slice(1).filter((val) => val !== '')]);
                            // console.log('process header data ',header,str, data);
                jsonData[header][key] = val;
            } else if (header === 'tracks') {
                // additional processing needed
                jsonData[header].push(str);
            } else {
                ([key,val] = [data[0],data[data.length-1]]);
                jsonData[header][key] = val;
            }

        }
    }

    // first pass at jsonData.
    // console.log(jsonData);


    const {patterns,tracks,orders} = parseTracks(jsonData);

    // console.log(patterns);
    jsonData.patterns = patterns;
    jsonData.orders = orders;

    jsonData.expandedTrack = getExpandedTrack(jsonData);

    return jsonData;    
}



const sleep = async (timeout = 1000) => {
    return new Promise(r => {
        setTimeout(r,timeout)
    });
}


const playFreq = (freq, audioCtx) => {
    let maxVolume = .05;
    const oscillator = audioCtx.createOscillator();
    oscillator.type = 'square';
    const time = audioCtx.currentTime;
    oscillator.frequency.setValueAtTime(freq, time); // value in hertz
    let gainNode = audioCtx.createGain();
    console.log({oscillator, gainNode});
    gainNode.connect(audioCtx.destination);

                //https://developer.mozilla.org/en-US/docs/Web/API/AudioParam/setTargetAtTime
    // setTargetAtTime(target, startTime, timeConstant)
    gainNode.gain.setTargetAtTime(maxVolume, time , 0);
    // gainNode.gain.setTargetAtTime(0, time + .01 , 0);
    gainNode.gain.setTargetAtTime(0, time + .05 , 0);

    oscillator.connect(gainNode);

    oscillator.start();

}

const getFreq = (str) => {
    console.log(str);

    // E-3 => E3
    str = str.replace('-','').trim();

    const freq = notes[str];

    // if (!freq) {
    //     console.log(str,notes);
    //     console.log(notes.E3);
    //     console.log(notes.G3);

    // }

    console.log({freq,str});

    return freq || 0;

    // return 900;
}

const playNote = async (str, audioCtx) => {

    const freq = getFreq(str);
    if (freq) {
        playFreq(freq,audioCtx);

    }

    await sleep(50);
}

const playCol = async (col) => {
    console.log(col);
    return [];
}

const playRow = async (row, audioCtx) => {
    const rowStr = row.columns.join(',');
    // console.log(rowStr);
    // ROW 00 : --- .. . ... ... : --- .. . ... ... : ... .. . ... : B-# 00 A ... : ... .. . F04
    //  --- .. . ... ... , --- .. . ... ... , ... .. . ... , B-# 00 A ... , ... .. . F04

    const notes = row.columns.map(col => {
        const colCols = col.trim().split(' ').map(val => val.trim());
        const note1 = colCols[0].substring(0,4);
        return note1;
    });

    // (5) ['D#3', 'D-7', 'E-2', '5-#', '...']0: "D#3"1: "D-7"2: "E-2"3: "5-#"4: "..."length: 5[[Prototype]]: Array(0)

    // TODO noise channel uses 5-#


    // const col0 = row.columns[0];


    // console.log(note1);

    // const notes = colCols.map(col => {
    // });

    console.log(notes.slice(0,3));

    await Promise.all(notes.slice(0,3).map((note) => {
        return playNote(note,audioCtx);
    }));

    return [];
}

// C#3

const getMidi = (noteName) => {
    // 12 * 4 = 48 + 12 = 60

    // C4 - 60 = 
    //0	1	2	3	4	5	6	7	8	9	10	11
    const noteAry = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
    const note = noteName.substr(0,2).replace('.', '');
    const noteIdx = noteAry.indexOf(note);
    if (noteIdx === -1) {
        return null;
    }
    const octave = noteName.substr(2,1);
    const midi = ((parseInt(octave, 10) * 12) + 12) + parseInt(noteIdx, 10);
    // console.log({noteIdx,octave, midi});
    return midi;

}

// C#3 00 . ...
const getMidiFromColumn = (column) => {
    return getMidi(column[0]);
}

/**
 * Convert to a midi compatable json format which is the response of
 * MidiParser.
 * 
 * https://github.com/Tonejs/Midi
 * 
 * libs\js\react-app-src\src\services\midi\MidiHelper.js
 * 
 * Sheet music compared with famitracker
 * https://youtu.be/Kg3UhEygz20?t=670
 * 
 * https://en.wikipedia.org/wiki/Pulses_per_quarter_note
 * In a music sequencer and MIDI clock, pulses per quarter note (PPQN), also known as pulses per quarter (PPQ), and ticks per quarter note (TPQN), is the smallest unit of time used for sequencing note and automation events.
 * 
 * So to get a quarter note as a tick duration.
 * ticks / quarter note
 * 
 * 
 * @param {} dataString 
 */
const famitrackerToMidi = (dataString) => {

    // I think each line is a 1/16 note?
    // ex

    const json = famitrackerToJson(dataString);

    // const { ppq, tempos } = header;
    // copied from midi file 
    const ppq = 256;

    //1	Andante	95	2.47 (60000 / (95 bpm * 256 ppq))

    //http://famitracker.com/forum/posts.php?id=74

    //https://famistudio.org/doc/import/
    
    // contants bpm.
    const tempos = [
        {
            // bpm: 95,
            bpm: 130

        }
    ];
    // TODO tempo
    const header = {
        ppq,
        tempos
    };
    // const tracks = [];

    const {patterns} = json;

    console.log(patterns);

    const tracks = [];
    patterns.forEach(pattern => {
        
        const instrument = {
            number: 0,
            name: 'piano?'
        }
        const track = {
            instrument
        };

        // const {notes,pitchBends,instrument,controlChanges} = track;
        // const {number,family, name, percussion} = instrument;


        const notes = [];
        let ticks = 0;
        pattern.rows.forEach(row => {
            const columns = row.columns;
            const midi = getMidiFromColumn(columns[0]);
            // const time = 0;
            // const duration = 0;
            const durationTicks = Math.floor(ppq / 4);

            if (!!midi) {
                notes.push({
                    durationTicks, // duration of ticks
                    ticks, // start time.
                    midi, // notes
                });
            }

            ticks += durationTicks;

        });
        track.notes = notes;
        tracks.push(track);

    })

    return {
        header,
        tracks
    }

}

const playFamitracker = async (dataString, audioCtx) => {
    const json = famitrackerToJson(dataString);
    console.log(json);


    // first pattern is just the noise channel which I have to work on.
    const patterns = json.patterns.slice(1);

    for (let pattern of patterns) {
        const {rows} = pattern;

        for (let row of rows) {
            const {columns} = row;
            await playRow(row, audioCtx);
            // for (let col of columns) {
                // const notes = await playCol(col,audioCtx);
            // }
        }
    }

    // const pattern0 = json.patterns[0];
    // const pattern = json.patterns[1];


    

    // playFreq(900,audioCtx);
    // playFreq
}



module.exports = {
    famitrackerToJson,
    playFamitracker,
    famitrackerToMidi,
    parsePatterns
};