import React from 'react';
//https://soundprogramming.net/file-formats/general-midi-instrument-list/
import { Midi } from '@tonejs/midi';
import MidiTrack from '../midi/MidiTrack';
import FilePath from '../../services/FilePath';

const trackSelectOptions = [
        {
            "description": "melody",
            'value': "/data/melody.mid"
        },
        {
            "description": "melody2",
            'value': "/data/melody2.mid"
        },
        {
            "description": "twinkle",
            'value': "/data/twinkle-twinkle-little-star.mid"

        },
    {
        description: "smash",
        value: '/data/SMASH-MOUTH.All-star.mid'
    }
    ];

const note_substring = [
        "C","C#","D","D#",
        "E","F","F#","G",
        "G#","A","A#","B"
    ];

const notes = [];

for(let noteNum=0; noteNum<=108;noteNum++){
    let octave = Math.floor(noteNum / 12);
    const str = `${note_substring[noteNum%12]}${octave}`;
    notes.push(str);
}

export default class MidiParser extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            tracks: [],
            file: null,
            trackSelectVal: '/data/melody.mid'
        };
    }

    async loadTrack() {
        // load a midi file in the browser
        // http://localhost:3000/data/melody.mid
        // TODO wrap the Midi tool into something that works without the getPath call.
        const urlPath = await FilePath.getPath(this.state.trackSelectVal);
        const midi = await Midi.fromUrl(urlPath);

        const {tracks} = midi;
        //get the tracks
        this.setState({
            ...this.state,
            loaded: true,
            midi,
            // tracks,
        });
    }

    async componentDidMount() {
        await this.loadTrack();

        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader
    }

    //https://simon-schraeder.de/posts/filereader-async/
    async readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
      
          reader.onload = () => {
            resolve(reader.result);
          };
      
          reader.onerror = reject;
      
          reader.readAsArrayBuffer(file);
        })
      }


    async loadTrackFromFile() {
        const file = this.state.file;
        const contentBuffer = await this.readFileAsync(file);
        // new Midi(arrayBuffer);
        const midi = new Midi(contentBuffer);
        // let fileData = new FileReader();
        // fileData.onloadend = handleFile;
        // fileData.readAsText(file);

        // const {tracks} = midi;

        this.setState({
            ...this.state,
            loaded: true,
            // tracks,
            midi,
        });

    }

    async componentDidUpdate( prevProps,  prevState) {
        console.log(`MidiParser`, `componentDidUpdate`);
        if (prevState.trackSelectVal !== this.state.trackSelectVal) {
            this.setState({
                ...this.state,
                loaded: false,
            });
            await this.loadTrack();

        } else if (prevState.file !== this.state.file) {
            await this.loadTrackFromFile();
        }
    }


    componentWillUnmount() {
    }


    // assumes single file.
    // TODO allow multiple files
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        this.setState({file}); /// if you want to upload latter
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    // 	/**
	//  * Download and parse the MIDI file. Returns a promise
	//  * which resolves to the generated MIDI file.
	//  * @param url The URL to fetch.
	//  */
	// static async fromUrl(url: string): Promise<Midi> {
	// 	const response = await fetch(url);
	// 	if (response.ok) {
	// 		const arrayBuffer = await response.arrayBuffer();
	// 		return new Midi(arrayBuffer);
	// 	} else {
	// 		throw new Error(`Could not load '${url}'`);
	// 	}
	// }


    //https://github.com/Tonejs/Midi
    render() {

        const midi = this.state.midi;


        const options = trackSelectOptions.map(el =>
            <option key={el.value} value={el.value}>{el.description || el.value}</option>
        );


        const trackSelect = (
            <select name="trackSelectVal" id="trackSelectVal"
                    value={this.state.trackSelectVal}
                    onChange={(event) => this.handleChange(event)}
            >
                {options}

            </select>
        );

        let tracksEl = <div>Loading</div>;

        if (midi) {
            tracksEl = midi.tracks.map((track, idx) => {
                    return (
                        <div key={idx}>
                            <div>
                                <h1>Track {idx+1} of {midi.tracks.length} </h1>
                            </div>
                            {/* TODO rename MidiPlayer cleanup etc */}
                            <MidiTrack midi={midi} track={track}></MidiTrack>
                        </div>
                    );
                }
            );
        }


        // parse display midi file.
        return (<div>
            <h1>Midi Parser</h1>

            {trackSelect}
            <input id="myInput"
                type="file"
                accept="audio/mid"
                // accept=".mid,.midi"
                ref={(ref) => this.upload = ref}
                onChange={this.onChangeFile.bind(this)}
                />

            <div>Tracks</div>
            {tracksEl}
        </div>);
    }
}