import React from 'react';
import MidiHelper from '../../services/midi/MidiHelper';
// TODO define instruments handle oscillators per note within the instrument class.
// new GrandPiano(audioContext)
import NoteHelper from '../../services/sound/NoteHelper';
import Synth from '../../services/sound/Synth/Synth';

const Piano = window.Piano;

let nh;

let noteHelpers = {};
let synths = {};

let defaultInstrument = 'organ';

const instruments = [
    'organ',
    'organ2',
    'brass',
    'bass',
    'chiptune',
    'triangle',
    'sawtooth',
    'square',
    'sine'
]

// console.log(Piano);
const instrumentOptions = instruments.map(el =>
    <option key={el} value={el}>{el || el}</option>
);

const audioContext = new (window.AudioContext || window.webkitAudioContext)();


export default class SynthComponent extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            midiNotes: {},
            freq: null,
            instrument: defaultInstrument
        }
    }

    getNoteHelper(midi) {
        if (!noteHelpers[midi]) {
            noteHelpers[midi] = new NoteHelper();
        }
        return noteHelpers[midi];
    }

    getSynth(instrument) {
        if (!synths[instrument]) {
             const synth = new Synth(audioContext, instrument);
             synths[instrument] = synth;

             for (let midiNote = 60; midiNote < 80; midiNote++) {
                const freq = MidiHelper.freq(midiNote);
                //  preload on new instrument
                synth.getGainOscillatorNodes(freq);
            }
        }
        return  synths[instrument];
    }

    handleKeyChange(midi,isPressed) {
        // console.log(`handleKeyChange`,midi,isPressed);
        let noteHelper = this.getNoteHelper(midi);

        let synth = this.getSynth(this.state.instrument);
        const freq = MidiHelper.freq(midi);

        if (isPressed) {
            // noteHelper.playStart(freq);
            synth.playStart(freq);
        } else {
            // noteHelper.playStop();
            synth.playStop(freq);
            // if (oscillator) {
            //     // TODO set decay time.
            //     // nh.playStop(oscillator);
            // }
        }


    }

    async componentDidUpdate( prevProps,  prevState) {
        Object.entries(this.state.midiNotes).forEach(([midi, isPressed]) => {
            if (prevState.midiNotes[midi] !== isPressed) {
                this.handleKeyChange(midi,isPressed);
            }
        });
        if (prevState.midiNote !== this.state.midiNote) {
            const freq = MidiHelper.freq(this.state.midiNote);
            console.log({freq});
            this.setState({
                freq
            })
            nh.playBounce(freq);
        }
        // if (prevProps.header !== this.props.header) {
        //     // this.setState({
        //     //     ...this.state,
        //     //     header: this.props.header,
        //     // });
        // }
    }

    // https://github.com/facebook/react/issues/10135
    async componentDidMount( prevProps,  prevState) {

        nh = new NoteHelper();
        // console.log(`componentDidMount`);
        const piano = new Piano();

        piano.on(
            'midi-note',
            ({midi}) => {
                // console.log(`midi played ${midi}`);
                // console.log('set state', this, this.setState);
                this.setState({
                    midiNotes: {
                        ...this.state.midiNotes,
                        [midi]: true
                    }
                })
            }
        );
        piano.on(
            'midi-note-up',
            ({midi}) => {
                // console.log('midi-note-up',{midi});
                this.setState({
                    midiNotes: {
                        ...this.state.midiNotes,
                        [midi]: false
                    }
                })
            }
        );
        window.addEventListener("keyup", (e) => {piano.handleKeyup(e)});

        window.addEventListener("keydown", (e) => {piano.handleKeydown(e)});
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    // https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    render() {



        const {midiNotes, freq} = this.state;

        const keys = Object.keys(midiNotes);

        let instrumentSelect = (<div>Loading</div>);


        instrumentSelect = (
            <select name="instrument" id="instrument"
                value={this.state.instrument}
                onChange={(event) => this.handleChange(event)}
            >
                {instrumentOptions}

            </select>
        );


        return (
            <div>
                    <h1>
                        Synth
                    </h1>

                    {this.state.instrument}

                    {instrumentSelect}


                    {Object.entries(midiNotes).map(([key, boolean]) => {
                        return (<div key={key}>Note: {key} {boolean ? 'true' : 'false'}</div>)
                    })}

                    {/* <div>Note: {midiNote} {freq}</div> */}
            </div>
        )
    }
}