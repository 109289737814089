import gameInit from '../game/game'
import winInit from '../ui/winSolitaire';


let moving;

let release;


export default (state, gameEl) => {

    const dealEl = document.getElementById('js-deck-deal');


    const game = gameInit(state, gameEl);
    const {win} = winInit(state, gameEl);

    const { getLastOnDesk, moveCardTo, getCard, getCardLocation, getLastOnPile, getAvailableDestinations  } = game;



        // TODO duplicate
        const faceUp = card => {
            state.cards[card].facingUp = true;
            requestAnimationFrame(() => {
                state.cards[card].el.classList.add('card--front');
                state.cards[card].el.classList.remove('card--back');
            });
        };


    /**
 * TODO check the game state independent of the gameEl.getBounding.
 * @returns 
 */
    const gameFinish = () => {
        // game finish check
        for (let i = 3; i >= 0; i--) {
            const l = state.finish[i].cards.length;
            if (l < 13) return;
        }

        const { width, height, left, top } = gameEl.getBoundingClientRect();
        win(width, height, left, top);
    };


    function getMousePosition(event) {
        return {
            x: event.pageX,
            y: event.pageY
        };
    }

    
    const faceUpLastOnDesk = index => {
        const card = getLastOnDesk(index);
        if (card !== null) {
            faceUp(card);
        }
    };


    const handleMove = event => {
        if (state.moving.capture) {
            const el = state.moving.element;
            const { x, y } = getMousePosition(event);

            el.style.left = `${x - state.moving.offset.x}px`;
            el.style.top = `${y - state.moving.offset.y}px`;
        }
    };


    const startMovingPosition = event => {
        const el = state.moving.element;
        const { x, y } = getMousePosition(event);
        const { top, left } = el.getBoundingClientRect();
        el.classList.add('card--moving');

        state.moving.offset = {
            x: x - left,
            y: y - top,
        };

        el.style.left = `${x - state.moving.offset.x}px`;
        el.style.top = `${y - state.moving.offset.y - 5}px`;
    };

    const dropCard = (x, y) => {
        for (const destination of state.moving.destinations) {
            const { width, height, left, top } = destination.offset;
            destination.el.classList.remove('finish-dest');
            if (
                (x > left && x < left + width) &&
                (y > top && y < top + height)
            ) {
                const { dest, pile, card } = destination.target;
                moveCardTo(dest, pile, card);

                destination.el.appendChild(state.moving.element);

                // check game finish
                gameFinish();

                // face up last on desk
                const {
                    location: originLocation,
                    pile: originPile
                } = state.moving.origin;

                if (originLocation === 'desk') {
                    faceUpLastOnDesk(originPile);
                }
            }
        }
    };

    /**
 * Mouse drag event done?
 * @param event 
 */
    const releaseMove = event => {
        clearTimeout(moving);
        clearTimeout(release);
        if (state.moving.capture) {
            release = setTimeout(() => {
                const { x, y } = getMousePosition(event);
                requestAnimationFrame(() => {
                    dropCard(x, y);

                    state.moving.element.classList.remove('card--moving');
                    state.moving.element.style.left = '';
                    state.moving.element.style.top = '';
                    state.moving.element = null;
                    state.moving.capture = false;
                    // console.log('release');
                });
            }, 100);
        }
    };




    const handleClick = index => event => {
        event.stopPropagation();
        const { el, facingUp } = getCard(index);

        if (state.moving.capture) return;
        releaseMove();

        if (facingUp) {
            const { location, pile } = getCardLocation(index);

            if (location === 'deal' && pile === 'deal') {
                const {
                    el: lastEl
                } = getLastOnPile('deal', 'deal');
                if (el !== lastEl) return;
            }

            const destinations = getAvailableDestinations(index, true);

            if (destinations.length > 0) {
                const {
                    target,
                    el: targetEl
                } = destinations[0];

                const {
                    dest: destTarget,
                    pile: pileTarget,
                    card: cardTarget
                } = target;

                moveCardTo(destTarget, pileTarget, cardTarget);

                // face up last cards on desk
                if (location === 'desk') {
                    faceUpLastOnDesk(pile);
                }
                targetEl.appendChild(el);
            } else {
                return;
            }
            gameFinish();
        } else {
            // is on deal deck
            const { location, pile } = getCardLocation(index);
            if (location === 'deal' && pile === 'pile') {
                const max = state.deal.pile.cards.length - 1;
                const min = Math.max(-1, max - 3);

                for (let i = max; i > min; i--) {
                    const card = state.deal.pile.cards[i];
                    const { el } = getCard(card);
                    faceUp(card);
                    moveCardTo('deal', 'deal', card);
                    dealEl.appendChild(el);
                }
            }
        }
    };

    const captureMove = index => event => {
        event.preventDefault();
        event.stopPropagation();
        const { el, facingUp } = getCard(index);
        if (facingUp) {
            const { location, pile } = getCardLocation(index);
            if (location === 'deal' && pile === 'deal') {
                const { el: lastEl } = getLastOnPile('deal', 'deal');
                if (el !== lastEl) {
                    return false;
                }
            }
            moving = setTimeout(() => {
                state.moving.element = event.target;
                state.moving.capture = true;
                state.moving.index = index;
                state.moving.card = getCard(index);
                state.moving.origin = getCardLocation(index);

                startMovingPosition(event);

                const destinations = getAvailableDestinations(index);
                state.moving.destinations = destinations;

                for (const dest of destinations) {
                    dest.el.classList.add('finish-dest');
                }

                for (let i = 0, l = destinations.length; i < l; i++) {
                    const { top, left, width, height } = destinations[i].el.getBoundingClientRect();
                    state.moving.destinations[i].offset = {
                        top: top,
                        left: left,
                        width: width,
                        height: height,
                    }
                }

                // console.log(state.moving.destinations);
            }, 200);
        }
    };

    return {
        handleMove,
        releaseMove,
        handleClick,
        captureMove
    }
}


