import React from 'react';
import {
    NavLink
} from "react-router-dom";

export default class NavBar extends React.Component {


    constructor(props) {
        super(props);

    }


    render() {
        // console.log(`render home`);

        return (<div>
            NavBar


            <div>
                {/* <Navigate to="/dashboard" replace={true} /> */}
                <NavLink
                    to="/demos"
                >
                    Demos
                </NavLink>

                <div>
                    <NavLink
                        to="/demos/midi"
                    >
                        Demos Midi
                    </NavLink>
                </div>
                <div>
                    <NavLink
                        to="/demos/midiparse"
                    >
                        Demos Midi Parser
                    </NavLink>

                    <div>
                        <NavLink
                            to="/demos/soundfont"
                        >
                            soundfont
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/synth"
                        >
                            Synth
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/demos/wav"
                        >
                            wav
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/famitracker"
                        >
                            famitracker
                        </NavLink>
                    </div>

                    
                    <div>
                        <NavLink
                            to="/md-editor"
                        >
                            markdown
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/solitaire"
                        >
                            Solitaire
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/checkers"
                        >
                            Checkers
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/chess"
                        >
                            Chess
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>);
    }
}