import CheckersAppMain from './CheckersAppMain';
import {
    BrowserRouter as Router,
    // Switch,
    Route,
    Routes,
    Navigate,
    Link,
    useParams,
    useLocation,
} from "react-router-dom";

const CheckersApp = (props) => {
    const location = useLocation();
    const params = useParams();


    return (
        <div >
            <CheckersAppMain  location={location} gameId={params.id}></CheckersAppMain>

        </div>
    )
}
export default CheckersApp;