import React from 'react';

import axios from 'axios';


const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_GAMEAPI}/v2/players`
});

export default class PickASide extends React.Component {

    //https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
    constructor(props) {
        super(props);

        this.state = {};
        this.state.input = {};

        this.state.gameType = props.gameType;
        this.state.gameId = props.gameId;
        this.state.playerName = props.playerName;

        this.state.loadingConfig = true;


        this.state.input.playerName = props.playerName || '';

        // callback function to set player details after login.
        this.state.setConfigCallback = props.setConfigCallback;

    }

    async loadConfig() {
        const {
            gameType,
            gameId,
            playerName
        } = this.state;

        const url = `/getByName/${playerName}/configs/${gameType}/${gameId}`;

        try {
            const result = await apiClient({
                method: 'GET',
                url
            });

            return JSON.parse(result.data.config);
        } catch (err) {
            // console.log(`loaded config`, result.status,result.data);

        }


        return {};






    }


    // get player details.
    async componentDidMount(prevProps, prevState) {

        let config = {};

        try {
            config = await this.loadConfig();

            console.log('loaded config', config);

            this.state.setConfigCallback(config);

        } catch (err) {

        }
        this.setState({
            ...this.state,
            loadingConfig: false,
        })

    }

    async componentDidUpdate(prevProps, prevState) {

    }

    //   handleChange(event) {
    //     this.setState({value: event.target.value});
    //   }

    handleChange(event) {
        let input = this.state.input;
        let val;
        if (event.target.type === 'checkbox') {
            val = event.target.checked;
        } else {
            val = event.target.value;
        }
        input[event.target.name] = val;
        this.setState({ input });
    }



    async handleSubmit(event, isNewUser = false) {
        console.log('An input was submitted: ', this.state.input);
        event.preventDefault();

        let player;
        // id and 
        if (isNewUser) {
            player = await this.createNewUserOrLogin();
        } else {
            player = await this.login();
        }
        const { playerName, id } = player;

        if (id) {
            // call callback in state
            this.state.playerCallback({ playerName, id });
        }
    }


    async updatePlayerConfig(config) {
        console.log({config},this.state);
        const {
            playerName,
            gameType,
            gameId,
        } = this.state;

        const url = `/updateConfig`;

        const result = await apiClient({
            url,
            method: 'POST',
            data: {
                playerName,
                gameType,
                gameId,
                config: JSON.stringify(config)
            }
        });

        console.log(result.status,result.data);

        this.state.setConfigCallback(JSON.parse(result.data.config));
    }

    render() {

        if (this.state.gameType === 'checkers') {
            return (
                <div>
                <button onClick={() => {
                    this.updatePlayerConfig({
                        orientation: 'b',
                    })
                }}>Black</button>
                <button onClick={() => {
                    this.updatePlayerConfig({
                        orientation: 'w',
                    })
                }}>Red / White</button>
            </div>
            );
        }
        if (this.state.gameType === 'chess') {
            return (
                <div>
                <button onClick={() => {
                    this.updatePlayerConfig({
                        orientation: 'b',
                    })
                }}>Black</button>
                <button onClick={() => {
                    this.updatePlayerConfig({
                        orientation: 'w',
                    })
                }}>White</button>
            </div>
            );
        }

        return (
            <div> PickASide component cannot handle game type {this.state.gameType}</div>
        )
    }
}
