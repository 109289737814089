import spriteImg from '../images/sprite';

const cardWidth = 71;
const cardHeight = 96;

export default (state, gameEl) => {
    const winSolitaire = (canvasWidth, canvasHeight, canvasLeft, canvasTop) => {
        const image = document.createElement('img');
        image.src = spriteImg;
        const canvas = document.createElement('canvas');
        canvas.style.position = 'absolute';
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        gameEl.appendChild(canvas);
    
        const context = canvas.getContext('2d');
        let card = 52;
        const particles = [];
    
        const drawCard = (x, y, spriteX, spriteY) => {
            context.drawImage(
                image,
                spriteX,
                spriteY,
                cardWidth,
                cardHeight,
                x,
                y,
                cardWidth,
                cardHeight
            );
        };
    
        const Particle = function (id, x, y, sx, sy) {
            if (sx === 0) sx = 2;
            const spriteX = ( id % 4 ) * cardWidth;
            const spriteY = Math.floor(id / 4) * cardHeight;
    
            // initial position of the card
            drawCard(x, y, spriteX, spriteY);
    
            this.update = () => {
                x += sx;
                y += sy;
    
                // is particle out of canvas
                if (x < -cardWidth || x > (canvas.width + cardWidth)) {
                    const index = particles.indexOf(this);
                    particles.splice(index, 1);
                    return false;
                }
    
                // bounce from floor
                if (y > canvas.height - cardHeight) {
                    y = canvas.height - cardHeight;
                    sy = -sy * 0.85;
                }
                sy += 0.98;
    
                drawCard(
                    Math.floor(x),
                    Math.floor(y),
                    spriteX,
                    spriteY
                );
                return true;
            };
        };
    
        const throwCard = (x, y) => {
            if (card < 1) return;
            card--;
            const particle = new Particle(
                card,
                x,
                y,
                Math.floor(Math.random() * 6 - 3) * 2,
                -Math.random() * 16
            );
    
            // const particle = new Particle(card, x, y, 0, 0);
            particles.push(particle);
        };
    
        let throwInterval = [];
        for (let i = 0; i < 4; i++) {
            const { left, top } = state.finish[i].el.getBoundingClientRect();
            throwInterval[i] = setInterval(function () {
                throwCard(left - canvasLeft, top - canvasTop);
            }, 1000);
            // throwCard(left - canvasLeft, top - canvasTop);
        }
    
        const updateInterval = setInterval(function () {
            let i = 0, l = particles.length;
            while (i < l) {
                particles[i].update() ? i++ : l--;
            }
            // clearInterval(updateInterval)
        }, 1000 / 60);
    
        function removeAnimation(event) {
            event.preventDefault();
            clearInterval(updateInterval);
            for (let i = 0; i < 4; i++) {
                clearInterval(throwInterval[i]);
            }
            canvas.parentNode.removeChild(canvas);
            document.removeEventListener('click', removeAnimation)
        }
        document.addEventListener('click', removeAnimation, false);
    };
    


    return {
        winSolitaire
    }
    
    

}


