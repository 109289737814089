

export const initCheckers = (updateCallback, initFen = `wwwwwwwwwwww        bbbbbbbbbbbb`, turnCount = 0) => {
    // console.log({updateCallback});
    // board not including blank spaces and is flat representation
    let board; // minimal flat board
    let fullBoard; // 2-d board
    // state.update

    // this.setState();

    const kingMeRowsByTurn = {
        'w': 7,
        'b': 0,
    };

    const turns = ['w','b'];

    let turn = turns[turnCount % 2];
    let winner = null;

    let boardStr = initFen;

    // boardStr = `wwwwwwwwwww    w  b bb bbbbbbbbb`;

    // // test king me.
    // boardStr = `wwwwwww ww w  w   b bbbbbbwbbb b`;

    // // test move king.
    // boardStr = `wwwwwww ww w  wb    bbbbbb bbbWb`;

    // // test end game.
    // boardStr = `           w B  wW          W   `;
    // boardStr = `           w B  wW     b    W   `;

    const initFullBoard = () => {
        fullBoard = [];
        let flatIdx = 0;
        for (let rowIdx = 0; rowIdx < 8; rowIdx++) {
            let squareColors;
            if (rowIdx % 2 === 0) {
                squareColors = ['w', 'b'];
            } else {
                squareColors = ['b', 'w'];
            }
            let row = [];
            for (let colIdx = 0; colIdx < 8; colIdx++) {
                let obj = {};
                let piece = ' ';

                obj.squareColor = squareColors[colIdx % 2];

                if (obj.squareColor === 'b') {
                    piece = board[flatIdx];

                    flatIdx++;
                }

                // row.push(obj);
                row.push({
                    ...obj,
                    piece
                });

            }
            fullBoard.push(row);
        }
        return fullBoard;
    }

    /**
 * Get the full board with
 * white / black squares and what piece occupies if any.
 */
    const getBoardFull = () => {
        if (!fullBoard) {
            initFullBoard();
        }
        return fullBoard;
      
    }

    const getFen = () => {
        let currentBoard = getBoardFull();
        let str = '';
        for (let row of currentBoard) {

            for (let cell of row) {
                const {piece,squareColor} = cell;
                if (squareColor === 'w') {
                    continue;
                }
                str += piece;
                // console.log({cell});
            }
        }
        return str;
    }

    const getTurn = () => turn;

    const isGameOver = () => !!winner;

    const getWinner = () => winner;

    const updateTurn = () => {
        turnCount++;
        turn = turns[turnCount % 2];
        const fen = getFen();
        const lost = fen.toLowerCase().indexOf(turn) === -1;
        if (lost) {
            winner = turns[(turnCount + 1) % 2]
        }
        updateCallback();
    }

    const updateBoard = (newTurnCount, fen, callback = true) => {
        turnCount = newTurnCount;
        turn = turns[turnCount % 2];
        console.log(`updateBoard`,turnCount,turn, turns);

        boardStr = fen;
        board = boardStr.split('');
        initFullBoard();

        if (callback) {
            updateCallback();
        }
    }

    const getPiece = (row,col) => {
        // find the piece being requested to move.
    }

    const moveFromTo = (from, to) => {
        const [row,col] = from;
        const [toRow, toCol] = to;

        console.log({
            row,col,
            toRow,toCol
        });
        const squareFrom = fullBoard[row][col];
        const squareTo = fullBoard[toRow][toCol];
        const {piece} = squareFrom;

        // Is it this pieces turn?
        if (piece.toLowerCase() !== turn) {
            return;
        }

        const isKing = piece === 'W' || piece === 'B';

        const orientation = (piece.toUpperCase() === 'W') ? 1 : -1;

        const kingMeRow = kingMeRowsByTurn[turn];
        const isKingMe = toRow === kingMeRow;

        // Is the to square unoccupied?
        if (squareTo.piece !== ' ') {
            console.log('illegal move', {squareFrom, squareTo})
            return;
        }

        // Is the square location +1, +1 or -1, -1?
        const movementRow = toRow - row;
        const movementCol = toCol - col;

        // down one to empty space.
        if (movementRow === orientation || isKing) {
            if (Math.abs(movementCol) === 1) {
                // is valid move
                fullBoard[row][col].piece = ' ';
                fullBoard[toRow][toCol].piece = isKingMe ? piece.toUpperCase() : piece;
                return updateTurn();
            }

        }

        // checking for jumps
        if (movementRow === 2 * orientation || (isKing && movementRow === -2 * orientation)) {
            console.log(`checking for jumps`);
            if (Math.abs(movementRow) !== Math.abs(movementCol)) {

            } else {
                // check if in between space has a piece of opposite color.
                // other option and from and 2 and then divide by 2.
                const inBetweenCol = col + Math.floor(movementCol / 2);
                const inBetweenRow = row + Math.floor(movementRow / 2);

                console.log({col, inBetweenCol, toCol});
                console.log({row, inBetweenRow, toRow});

                const inBetweenSpace = fullBoard[inBetweenRow][inBetweenCol];
                console.log({inBetweenSpace});

                const {piece: inBetweenPiece} = inBetweenSpace;

                if (inBetweenPiece.toUpperCase() !== piece.toUpperCase()) {
                    // captured piece.
                    fullBoard[inBetweenRow][inBetweenCol].piece = ' ';

                    fullBoard[row][col].piece = ' ';
                    fullBoard[toRow][toCol].piece = isKingMe ? piece.toUpperCase() : piece;
                    return updateTurn();
                }




            }


        }

        //check jump


        console.log('illegal move', {squareFrom, squareTo,movementRow, movementCol})



    }

    /**
     * 
     * @param {*} row 
     * @param {*} col 
     */
    const clickPiece = (row,col) => {
        // console.log({row,col});
        const piece = getPiece(row,col);
    }


    updateBoard(turnCount, boardStr, true);




    return {
        updateBoard,
        moveFromTo,
        getBoardFull,
        clickPiece,
        getFen,
        getTurn,
        getTurnCount: () => turnCount,
        isGameOver,
        getWinner,
    };
}
