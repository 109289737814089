import React from 'react';
import MidiHelper from '../../services/midi/MidiHelper';

export default class MidiNotestViewer extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            // required to show true start and end times in seconds.
            // bpm: props.bpm,
            // ppq: props.ppq,
            midi: props.midi,
            track: props.track,
            showNotesFull: false
        }
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }


    //https://github.com/Tonejs/Midi
    render() {
        const {showNotesFull, midi, track} = this.state;

        const {notes} = track;

        const {notes: realtimeNotes, totalTime} = MidiHelper.getSongFromMidiTrack(midi,track);

        let notesEl = (<div>
                {notes.map((note, idx) => {
                    const {duration, durationTicks, midi: midiNote, name, ticks, time, velocity} = note;
                    return (<div key={idx}>
                        <span>{name} - {ticks} - {durationTicks} - {Math.floor(velocity * 100)} - {midiNote}</span>
                    </div>);
                })}
        </div>);            
        // console.log(`midi track`,{midi});

        // include magenta player.
        // const magentaSong = this.state.song;
        return (
            <div>

                <div>Notes Count: {notes.length}</div>
                <div>Duration: {totalTime}</div>
                <div>First: <pre>{JSON.stringify(notes[0])}</pre></div>
                <div>Last: <pre>{JSON.stringify(notes[notes.length - 1])}</pre></div>

                <input type="checkbox" name="showNotesFull" id="showNotesFull"
                    value={showNotesFull}
                    onChange={(event) => this.handleChange(event)}
            >

            </input>

                {showNotesFull && notesEl}

                {/*<pre>{JSON.stringify(notes,null,2)}</pre>*/}
            </div>
        )
    }
}