import {
    BrowserRouter as Router,
    // Switch,
    Route,
    Routes,
    Navigate,
    Link,
    useParams,
    useLocation,
} from "react-router-dom";

// import init from "./index.js"
import SolitaireAppMain from "./SolitaireAppMain.js";

const SolitaireApp = (props) => {
    const location = useLocation();
    const params = useParams();

    console.log({location,params});

    // return (<div>test </div>)

    return (<SolitaireAppMain></SolitaireAppMain>);
}
export default SolitaireApp