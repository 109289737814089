const keyCodes = [
];


const actionMap = {
    65: 'left',
    83: 'down',
    68: 'right',
    87: 'rotate',

}




// https://stackoverflow.com/questions/45831911/is-there-any-eventemitter-in-browser-side-that-has-similar-logic-in-nodejs
// https://medium.com/@zandaqo/eventtarget-the-future-of-javascript-event-systems-205ae32f5e6b


export default class TetrisController {


    constructor() {
        this.eventListeners = {};

        this._input = {
            rotate: false,
            down: false,
            left: false,
            right: false
        }

        this.inputActions = [];
    }

    get input() {
        return this._input;
    }

    /**
     * Process input events and return actions that should
     * be taken.
     * 
     * TODO I think SDL has a function for processing events that
     * might be similar to this.
     * 
     */
    getActions() {
        let left = 0;
        let right = 0;
        let down = 0;
        let rotate = 0;
        let obj = {
            left,
            right,
            down,
            rotate
        }

        this.inputActions.forEach(([actionName,isPressDown]) => {
            if (isPressDown) {
                obj[actionName] += 1;
            }
        });
        this.inputActions = [];

        return obj;

    }

    on(name, callback) {
        this.eventListeners[name] =  this.eventListeners[name] || [];

        this.eventListeners[name].push(callback);

    }

    emit(name, data) {

        if (this.eventListeners[name]) {
            this.eventListeners[name].forEach(func => func(data));
        }
    }

    handleKeydown(e) {
        // console.log(`handleKeydown`,e.keyCode);

        // this.emit('keydown', e.keyCode);

        const action = actionMap[e.keyCode];

        if (action) {
            this._input[action] = true;
            this.inputActions.push([action,true])
            // this.emit('action', action);
        }

    }

    handleKeyup(e) {
        // this.emit('keyup', e.keyCode);
        const action = actionMap[e.keyCode];

        if (action) {
            this._input[action] = false;
            this.inputActions.push([action,false])
            // this.emit('action', action);
        }

    }


}

