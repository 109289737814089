import React from 'react';

import axios from 'axios';


const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_GAMEAPI}/v2/players`
  });

export default class GameLogin extends React.Component {

    //https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
    constructor(props) {
        super(props);



        this.state = {};
        this.state.input = {};

        this.state.input.playerName = props.playerName || '';

        // callback function to set player details after login.
        this.state.playerCallback = props.playerCallback;

    }


    // get player details.
    async componentDidMount(prevProps, prevState) {

    }

    async componentDidUpdate(prevProps, prevState) {
    }

    //   handleChange(event) {
    //     this.setState({value: event.target.value});
    //   }

    handleChange(event) {
        let input = this.state.input;
        let val;
        if (event.target.type === 'checkbox') {
            val = event.target.checked;
        } else {
            val = event.target.value;
        }
        input[event.target.name] = val;
        this.setState({ input });
    }

    async createNewUserOrLogin() {
        const {
            playerName
        } = this.state.input;

        console.log('create new user as', playerName);

        try { 
            const result = await apiClient({
                method: 'POST',
                url: '/',
                data: {
                    playerName
                }
            });

            console.log(result.status);
            return result.data;
        } catch (err) {
            console.log(err);
        }
    }

    async login() {
        const {
            playerName
        } = this.state.input;

        console.log('logging in as', playerName);

        try {
            const result = await apiClient({
                method: 'GET',
                url: 'getByName/' + playerName
            });

            console.log(result.status);
            return result.data;
        } catch (err) {
            console.log(err);
        }

    }

    async handleSubmit(event, isNewUser = false) {
        console.log('An input was submitted: ', this.state.input);
        event.preventDefault();

        let player;
        // id and 
        if (isNewUser) {
            player = await this.createNewUserOrLogin();
        } else {
            player = await this.login();
        }
        const {playerName,id} = player;

        if (id) {
            // call callback in state
            this.state.playerCallback({playerName,id});
        }
    }

    render() {

        return (

            <div className={`game-login`}>
                <div>
                    Set your username. This will be saved in this browser only to keep track of the games you are in.
                </div>
                <div >
                    <label>
                        Name:
                        <input type="text" name="playerName" value={this.state.input['playerName']}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>

                    {/* <br/> */}
                    {/* <br/> */}

                    {/* <input type="submit" value="Submit" /> */}
                    <button onClick={(event) => this.handleSubmit(event)} >Login</button>
                    <br />
                    <br />

                    <button onClick={(event) => this.handleSubmit(event, true)} >New User</button>
                    <br />

                </div>
            </div>

        )
    }
}
