


import * as showdown from 'showdown';
// var showdown = require('showdown');
// var showdown  = require('./showdown');

let converter = new showdown.Converter();
// const converter = showdown.Converter();

var _Templater = {
    format: function(template, values) {
        //
        // Utility function that replaces placeholders with parameterized values
        //
        // Example:
        // Inputs:
        // template = 'Here is some text: %text%'
        // values = {'text', 'Hello I am text!'}
        //
        // Output:
        // 'Here is some text: Hello I am text!'
        //
        // @param template The template to do replacements on.  Fields to be replaced should be surrounded
        //                 by percentage signs (e.g. %field%)
        // @param values A Javascript object literal containing the names of the fields to be replaced
        //               along with the replacement values (e.g. {'field': 'Replacement text'}
        for (const value in values) {
            template = template.replace(new RegExp('%' + value + '%', 'g'), values[value], 'g');
        }
        return template;
    }
}

// Capitalizes a string
var capitalize = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

var _CreateRadioButtonInput = function (text) {
    //
    // Creates a group of radio buttons.
    // Converts text of the form:
    //
    // sex = (x) male () female
    //
    // into:
    //
    // <label>Sex:</label>
    // <input type="radio" name="sex" id="male" value="male" checked="checked"/>
    // <label for="male">Male</label>
    // <input type="radio" name="sex" id="female" value="female"/>
    // <label for="female">Female</label>
    //
    // Right now it only works on single-line expressions.
    // 
    // TODO: Make this work across multiple lines.
    //
    var regex = /(\w[\w \t\-]*)=[ \t]*(\(x?\)[ \t]*[\w \t\-]+[\(\)\w \t\-]*)/g;
    return text.replace(regex, function(...args: any) {
        const name = args[1];
        const options = args[2];

        var cleanedName = name.trim().replace(/\t/g, ' ');
        var inputName = cleanedName.replace(/[ \t]/g, '_').toLowerCase();
        var cleanedOptions = options.trim().replace(/\t/g, ' ');
        var labelName = cleanedName + ":";
        var output = '<label>' + labelName + '</label>';
        var optRegex = /\((x?)\)[ \t]*([a-zA-Z0-9 \t_\-]+)/g;
        var match = optRegex.exec(cleanedOptions);
        while (match) {
            var id = match[2].trim().replace(/\t/g, ' ').replace(/[ \t]/g, '_').toLowerCase();
            var checkboxLabel = match[2].trim().replace(/\t/g, ' ');
            var checked = match[1] == 'x';
            output += '<input type="radio" name="' + inputName + '" id="' + id + 
                      '" value="' + id + '" ' + (checked ? 'checked="checked"' : '') + '/>';
            output += '<label for="' + id + '">' + checkboxLabel + '</label>';
            match = optRegex.exec(cleanedOptions);
        }
        return output;
    });
}

var _CreateFormTextInput = function (text: string) {
    //
    // Creates a form text input element.
    // Converts text of the form:
    //
    // "first name = ___"
    //
    // into a form input like:
    //
    // <label for="first_name">First Name:</label>
    // <input type="text" id="first_name" name="first_name" size="20"/>
    //
    // Or specifying input field size:
    //
    // "first name = ___[50]"
    //
    // into:
    // 
    // <label for="first_name">First Name:</label>
    // <input type="text" id="first_name" name="first_name" size="50"/>
    //
    // Or specifying a required field:
    //
    // "first name* = ___"
    //
    // into:
    //
    // <label for="first-name" class="required-label">First Name*:</label>
    // <input type="text" id="first-name" name="first-name" size="20" class="required-input"/>
    //
    // Specifics:
    // * Each form input created in this way should be on its own line.
    // * Requires exactly 3 underscores on the right-hand side of the equals sign.
    // * Currently does not check whether a <form> tag has been opened.
    // 
    return text.replace(/(\w[\w \t\-]*(\*)?)[ \t]*=[ \t]*___(\[\d+\])?/g, function(...args: any) {
        const lhs = args[1];
        const required = args[2];
        let size = args[3];

        var cleaned = lhs.replace(/\*/g, '').trim().replace(/\t/g, ' ').toLowerCase();
        var inputName = cleaned.replace(/[ \t]/g, '-'); // convert spaces to hyphens
        var labelName = cleaned.split(' ').map(capitalize).join(' ') + (required ? '*:' : ':');
        var template = '<label for="%id%" class="%labelClass%">%label%</label>' +
                       '<input type="text" id="%id%" name="%id%" size="%size%" class="%inputClass%"/>';
        size = size ? size.match(/\d+/g)[0] : 20;
        var labelClass = required ? 'required-label' : '';
        var inputClass = required ? 'required-input' : '';
        return _Templater.format(template, {id: inputName, label: labelName, size: size, labelClass: labelClass, inputClass: inputClass});
    });
};

const _Detab = function (text: string) {
    // attacklab: Detab's completely rewritten for speed.
    // In perl we could fix it by anchoring the regexp with \G.
    // In javascript we're less fortunate.
    // expand first n-1 tabs
    text = text.replace(/\t(?=\t)/g, "    "); // attacklab: g_tab_width
    // replace the nth with two sentinels
    text = text.replace(/\t/g, "~A~B");

    // use the sentinel to anchor our regex so it doesn't explode
    text = text.replace(/~B(.+?)~A/g, function (...args: any) {
        const m1 = args[1];
        var leadingText = m1;
        var numSpaces = 4 - leadingText.length % 4; // attacklab: g_tab_width
        // there *must* be a better way to do this:
        for (var i = 0; i < numSpaces; i++) leadingText += " ";

        return leadingText;
    });

    // clean up sentinels
    text = text.replace(/~A/g, "    "); // attacklab: g_tab_width
    text = text.replace(/~B/g, "");

    return text;
};


export const makeHtml = (text: string) => {
    // converter = new showdown.Converter();

    // attacklab: Replace ~ with ~T
    // This lets us use tilde as an escape char to avoid md5 hashes
    // The choice of character is arbitray; anything that isn't
    // magic in Markdown will work.
    text = text.replace(/~/g, "~T");

    // attacklab: Replace $ with ~D
    // RegExp interprets $ as a special character
    // when it's in a replacement string
    text = text.replace(/\$/g, "~D");

    // Standardize line endings
    text = text.replace(/\r\n/g, "\n"); // DOS to Unix
    text = text.replace(/\r/g, "\n"); // Mac to Unix
    // Make sure text begins and ends with a couple of newlines:
    text = "\n\n" + text + "\n\n";

    // Convert all tabs to spaces.
    text = _Detab(text);

    // Strip any lines consisting only of spaces and tabs.
    // This makes subsequent regexen easier to write, because we can
    // match consecutive blank lines with /\n+/ instead of something
    // contorted like /[ \t]*\n+/ .
    text = text.replace(/^[ \t]+$/mg, "");


    text = _CreateFormTextInput(text);
    text = _CreateRadioButtonInput(text);

    const html = converter.makeHtml(text);

    return html;
}


// losely based on markdown to meet my own needs.
// module.exports = makeHtml;


export const test = () => {
    return true;
};

