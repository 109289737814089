import React from 'react';

// https://github.com/jhlywa/chess.js
import initSolitaireMain from './index.js'
import './index.scss';


// https://github.com/rjanjic/js-solitaire

export default class SolitaireAppMain extends React.Component {

  //https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
  constructor(props) {
    super(props);
  }

  // async componentDidMount( prevProps,  prevState) {
  async componentDidMount( prevProps,  prevState) {
    console.log('componentDidMount');
    initSolitaireMain();
  }
//   async componentDidUpdate( prevProps,  prevState) {
//     return this.loadGameOnUpdate();
//   }


  render() {
//     return ( <div className="solitaireContainer">
//         <div className="window">

// </div>
//     </div>)
    return (

        <div className="solitaireContainer">
        <div className="window">
        <div className="window__inner">
            <div className="window__heading">
                <div className="window__heading-icon"></div>
                Solitaire
            </div>
            <div className="window__actions">
                <button type="button" id="js-reset" className="new-game">New game</button>
            </div>
            <div className="window__content">
                <div className="window__content-inner">
                    <div className="solitaire" id="js-solitaire">
                        <div id="js-finish" className="finish-deck"></div>
                        <div id="js-board" className="board-deck"></div>

                        <div className="deck">
                            <div id="js-deck-pile" className="deck__pile"></div>
                            <div id="js-deck-deal" className="deck__deal"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* <div className="floating-solitaire-background"></div> */}

    </div>

    )
  }
}
