import React from 'react';
import BaseComponent from '../../BaseComponent';
import MidiSummaryTable from '../../midi/summary/MidiSummaryTable';
import MidiSummary from '../../midi/MidiSummary';
import { famitracker } from '@russ/common-libs';

const {famitrackerToJson, famitrackerToMidi} = famitracker;

// TODO interface for famitrackerJson.
// interface

export default class FamitrackerSummary extends BaseComponent {



    // TODO track and pattern.
    // patterns are small reusable segments and tracks represent the entire song.
    // Tracks make use of patterns to keep the storage requirements low especially sense they
    // were used for older systems.

//     0
// : 
// "TRACK  64   6 150 \"New song\""
// 1
// : 
// "COLUMNS : 1 1 1 1 1"
// 2
// : 
// "ORDER 00 : 00 00 00 00 00"

    constructor(props) {
        super(props);


        this.state = {
            famitrackerText: props.famitrackerText
        }
        this.state.famitrackerJson = famitrackerToJson(this.state.famitrackerText);

        this.state.famitrackerSong = famitrackerToMidi(this.state.famitrackerText);

    }

    render() {

        const {famitrackerJson, famitrackerSong} = this.state;

        // console.log(famitrackerJson);

        const {stringArray, globalSettings,instruments,macros,songInfo,patterns,tracks, orders, expandedTrack} = famitrackerJson;

        console.log(`patterns`,patterns);
        return (<>

<MidiSummary midi={famitrackerSong}></MidiSummary>
<pre>
    {JSON.stringify(expandedTrack,null,2)}
{/* {orders.map(({key,trackPatterns}) => (
        {key}
    )
)} */}

{/* {patterns.join('\n')} */}

</pre>
{/* <pre>
    {JSON.stringify(famitrackerSong,null,2)}
</pre> */}
        {/* <MidiSummaryTable midi={famitrackerSong}></MidiSummaryTable> */}
<pre>
{tracks.join('\n')}

</pre>
<pre>
${this.state.famitrackerJson.stringArray.join('\n')}
</pre>
        </>)
    }
}