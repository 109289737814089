// import {dealCards} from './dealCards';
import gameInit from '../game/game'
import inputInit from '../input/input'
import spriteImg from '../images/sprite';


/**
 * Initialized by calling miscInit()
 */
export default (state, gameEl) => {

    // add sprite
    const css = document.createElement('style');
    const styles = `.card--front { background-image: url("${spriteImg}"); }`;
    css.appendChild(document.createTextNode(styles));
    document.head.appendChild(css);

    const deckPileEl = document.getElementById('js-deck-pile');
    const dealPileEl = document.getElementById('js-deck-pile');

    const game = gameInit(state, gameEl);
    const input = inputInit(state, gameEl);


    
    window.onmousemove = input.handleMove;
    window.onmouseup = input.releaseMove;


    const {getCard, getLastOnDesk, placeCardTo} = game;
    const {handleClick, releaseMove, captureMove} = input;

    // TODO duplicate
    const faceUp = card => {
        state.cards[card].facingUp = true;
        requestAnimationFrame(() => {
            state.cards[card].el.classList.add('card--front');
            state.cards[card].el.classList.remove('card--back');
        });
    };
    

    // TODO
    const appendToCard = (target, card) => {
        state.cards[target].el.appendChild(
            state.cards[card].el
        )
    };

    const appendToDesk = (desk, card) => {
        state.desk[desk].el.appendChild(
            state.cards[card].el
        )
    };

    /**
     * Called on start of game once.
     */
    const dealCards = () => {
        let card = 0;
        for (let i = 0; i < 7; i++) {
            for (let j = i; j < 7; j++) {
                const last = getLastOnDesk(j);
                if (last !== null) {
                    appendToCard(last, card)
                } else {
                    appendToDesk(j, card);
                }

                placeCardTo('desk', j, card);

                if (j === i) {
                    faceUp(card);
                }
                card++;
            }
        }
    }


    

    const faceDown = card => {
        state.cards[card].facingUp = false;
        state.cards[card].el.classList.remove('card--front');
        state.cards[card].el.classList.add('card--back');
    };

    /**
     * Called at the start after the decks are shuffled.
     * TODO shuffle decks as a part of this call.
     */
    const resetGame = () => {
        // clear decks
        for (let i = 0; i < 7; i++) {
            state.desk[i].cards = [];
        }
        for (let i = 0; i < 4; i++) {
            state.finish[i].cards = [];
        }
        state.deal.pile.cards = [];
        state.deal.deal.cards = [];

        // randomise cards
        state.cards.sort(() => (Math.random() < .5) ? -1 : 1);

        // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
        // re-assign indexes
        requestAnimationFrame(() => {
            for (let i = 0, l = state.cards.length; i < l; i++) {
                const { facingUp, el } = state.cards[i];
                state.deal.pile.cards.push(i);

                el.onmousedown = captureMove(i);
                el.onmouseup = releaseMove;
                el.onclick = handleClick(i);

                if (facingUp) {
                    faceDown(i);
                }

                dealPileEl.appendChild(el);
            }
            dealCards();
        });
    }


    const restartDeal = () => {
        state.deal.pile.cards = state.deal.deal.cards;
        state.deal.deal.cards = [];

        for (const card of state.deal.pile.cards) {
            const { el } = getCard(card);
            faceDown(card);
            deckPileEl.appendChild(el);
        }
    }


    return {
        resetGame,
        restartDeal
    }


}