// https://en.wikipedia.org/wiki/Pulses_per_quarter_note

import React from 'react';
import Soundfont from 'soundfont-player';
import FilePath from '../../services/FilePath';
import MidiHelper from '../../services/midi/MidiHelper';
import MidiSummary from '../midi/MidiSummary';
import MidiPlayer from './../../services/sound/MidiPlayer';
import Form from 'react-bootstrap/Form';
import FileLoader from '../../services/FileLoader';
import { famitracker } from '@russ/common-libs';
import FamitrackerSummary from './summary/FamitrackerSummary';

const {famitrackerToJson, famitrackerToMidi} = famitracker;

// https://github.com/danigb/sample-player/blob/master/lib/player.js
// TODO I may need to create my own fork of Soundfont player.

// var ac = new AudioContext();
// can only be created after some user input.
let audioContext = new AudioContext();

const masterVolumeNode = audioContext.createGain();

// treat gainNode as main context.
let ac = audioContext;

masterVolumeNode.connect(audioContext.destination);
masterVolumeNode.gain.value = 1;


// instrument	note count	start	stop	duration
// distortion guitar #30	676	0	0	0
// overdriven guitar #29	273	0	0	0
// string ensemble 1 #48	384	0	0	0
// string ensemble 1 #48	222	0	0	0
// power kit #16	559	0	0	0


const noteMapping = {
    'q': 60,
    'w': 62, //D4
    'e': 64,
    'r': 65,// f4
    't': 67,
    'y': 69, // A4 concert pitch
    'u': 71,
    'i': 72,
    // 'e': 68,
    // 'e': 69,

};




let instruments = {
    'acoustic_grand_piano': null,
    'overdriven_guitar': null,
    // 'distortion_guitar': null,
    'trumpet': null,
};

const preloadedInstruments = [
    'acoustic_grand_piano'
];


const fallback = {
    'distortion_guitar': 'overdriven_guitar'
};

const midiInstrumentMappings = {
    0: 'acoustic_grand_piano',
    30: 'distortion_guitar',
};

const defaultInstrumentKey = 'acoustic_grand_piano';

// TODO famitracker midi file.
export default class SoundfontPlayerDemo extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            header: props.header,
            loadingInstruments: false,
            instrument: defaultInstrumentKey,
            currentKey: '',
            file: null,
            midiLoaded: 0,
            midi: null,
            volume: 50,
            famitrackerLoaded: 0,
            famitrackerFile: null,
            famitrackerText: null,
        }
    }

    getFileType(filename) {
        console.log({filename});
        const split = filename.split('.');
        if (split[1] === 'txt') {
            return 'famitracker';
        }
        return 'midi';
    }

    async loadFamitrackerFile(file) {
        console.log('load famitracker');

        const result = await FileLoader.readTextFile(file);
        console.log('load famitracker', {result});

        // const json = famitrackerToJson(result);

        
        // console.log({json});

        this.setState({
            famitrackerLoaded: Date.now(),
            famitrackerFile: file.name,
            famitrackerText: result,
        })

    }


    async componentDidUpdate(prevProps, prevState) {


        if (prevState.file !== this.state.file) {
            console.log(this.state.file);
            const filetype = this.getFileType(this.state.file.name);
            if (filetype == 'midi') {
                const midi = await MidiHelper.loadTrackFromFile(this.state.file);
                this.setState({ midi, midiLoaded: Date.now() });
            } else if (filetype === 'famitracker') {
                await this.loadFamitrackerFile(this.state.file);
            }

        }

        if (prevState.volume !== this.state.volume) {
            masterVolumeNode.gain.value = Math.min(1,this.state.volume / 100);
        }
    }

    getInstrumentKeyFromNum(num) {
        if (midiInstrumentMappings[num]) {
            return midiInstrumentMappings[num];
        }
        // acoustic piano.
        return midiInstrumentMappings[0];
    }

    async getInstrumentByNum(num, useExisting = true) {
        return this.getInstrument(this.getInstrumentKeyFromNum(num), useExisting)
    }

    async getInstrument(key, useExisting = true) {
        let instrumentKey = key;
        try {
            if (instruments[instrumentKey] === undefined) {
                instrumentKey = fallback[instrumentKey] || midiInstrumentMappings[0];
            }
            if (!instruments[instrumentKey]) {
                const ac = this.getAudioContext();
                instruments[instrumentKey] = await Soundfont.instrument(ac, await FilePath.getPath(`/data/soundfonts/${instrumentKey}-mp3.js`));
            }
            return instruments[instrumentKey]
        } catch (err) {
            console.error(`Failed to fetch instrument ${instrumentKey}`);
            throw err;
        }

    }

    async componentDidMount() {
        masterVolumeNode.gain.value = Math.min(1, this.state.volume / 100);
    }

    //https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    getMsPerTick({ bpm, ppq }) {
        return 60000 / (bpm * ppq);
    }

    async playNote(note, {
        time,
        duration,
        gain,
        attack,
        decay,
        sustain,
        // adsr: [attack,decay,sustain,release]
    } = {}) {
        if (this.state.instrument) {
            const instrument = await this.getInstrument(this.state.instrument);
            instrument.play(note, ac.currentTime, { duration: .5 });
        }
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    // assumes single file.
    // TODO allow multiple files
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        this.setState({ file }); /// if you want to upload latter
    }

    // TODO keyup to stop note.
    handleKeyDown(e) {
        const { key, keyCode } = e;
        this.setState({
            ...this.state,
            currentKey: key
        });

        if (noteMapping[key]) {
            this.playNote(noteMapping[key]);
        }
    }

    getAudioContext() {
        if (!ac) {
            ac = new AudioContext();
        }
        return ac;
    }

    async playFamiTrackerFile(famitrackerText) {
       const midi = famitrackerToMidi(famitrackerText);
       if (midi) {
            const mainNode = masterVolumeNode;

            return MidiPlayer.playMidi({ midi, ac, mainNode });

        }
    }

    async playAllTracks() {
        // midiInstrumentMappings
        const { midi } = this.state;
        if (midi) {
            const mainNode = masterVolumeNode;

            return MidiPlayer.playMidi({ midi, ac, mainNode });

        }
    }

    // https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    render() {

        const { midi, famitrackerText, famitrackerFile, famitrackerLoaded } = this.state;

        let instrumentSelect = (<div>Loading</div>);

        //  TODO smoother instrument loading. Load piano first and then load others.
        if (!this.state.loadingInstruments) {
            const options = Object.keys(instruments).map(el =>
                <option key={el} value={el}>{el || el}</option>
            );


            instrumentSelect = (
                <select name="instrument" id="instrument"
                    value={this.state.trackSelectVal}
                    onChange={(event) => this.handleChange(event)}
                >
                    {options}

                </select>
            );
        }

        if (this.state.instrument) {

        }

        const volumeStyle = {'width': '500px'}

        return (
            <div>
                <div>Famitracker Player</div>

                {/* TODO capture input and have a piano keyboard visual */}
                <input
                    onChange={() => { }}
                    value={this.state.currentKey}
                    onKeyDown={(e) => this.handleKeyDown(e)}></input>

                <div style={volumeStyle}>
                    <Form.Label>Volume {this.state.volume}</Form.Label>
                    <Form.Range 
                    name="volume"
                    onChange={(e) => { this.handleChange(e) }}
                    value={this.state.volume}
                     />
                </div>

                {instrumentSelect}

                <div>{this.state.instrument}</div>

                <input id="myInput"
                    type="file"
                    accept="audio/mid,.txt"
                    // accept=".mid,.midi"
                    ref={(ref) => this.upload = ref}
                    onChange={this.onChangeFile.bind(this)}
                />

                {midi && (
                    <div>
                        <button type="button" onClick={() => this.playTrack1()}>Play Track 1</button>
                        <button type="button" onClick={() => this.playAllTracks()}>Play All Tracks</button>

                    </div>
                )}
                <div>

                </div>
                <div>{this.state.midiLoaded}</div>
                <MidiSummary key={`summary-${this.state.midiLoaded}`} midi={midi}></MidiSummary>



                {famitrackerText && (
                    <div>
                        <button type="button" onClick={() => this.playFamiTrackerFile(famitrackerText)}>Play Famitracker File</button>
                    </div>
                )}
                
                {famitrackerText && <FamitrackerSummary key={famitrackerLoaded} famitrackerText={famitrackerText}></FamitrackerSummary>}

            </div>
        )
    }
}