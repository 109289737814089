// https://en.wikipedia.org/wiki/Pulses_per_quarter_note

import React from 'react';

export default class MidiH extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            header: props.header,
        }

        // this.state = {
            // track: props.track
            // midi: props.midi.track
        // };
    }


    async componentDidUpdate( prevProps,  prevState) {
        if (prevProps.header !== this.props.header) {
            this.setState({
                ...this.state,
                header: this.props.header,
            });
        }
    }

    //https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    getMsPerTick ({bpm,ppq}) {
        return 60000 / (bpm * ppq);
    }

    // https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    render() {

        // TODO use midi data for tempo settings
        const header = this.state.header;


        const {ppq, tempos, timeSignatures} = header;


        const tempo1 = tempos[0];
        const {bpm,ticks} = tempo1;

        return (
            <div>
                <div>Midi Header</div>

                <pre>Time Sig: {JSON.stringify(timeSignatures)}</pre>
                <pre> MS per Tick: {`60000 / (${bpm} * ${ppq})`} {this.getMsPerTick({bpm,ppq})}</pre>
                <div>Pulses_per_quarter_note: {ppq}</div>
                <div>tempo 1 bpm: {bpm}</div>
                {/* <pre>{JSON.stringify(header)}</pre> */}

            </div>
        )
    }
}