import React from 'react';
import axios from 'axios';

// TODO define this in package.json

// TODO @russ/cards
// import { Pyramid } from 'cards';
// Attempted import error: 'Pyramid' is not exported from 'cards' (imported as 'Pyramid').

// TODO test the package build better.
// import {Pyramid} from '../../libs/cards/';
// import * as cardsLib from '../../libs/cards/';
// import * as cardsLib from 'cards';


// Attempted import error: 'Pyramid' is not exported from 'cards' (imported as 'Pyramid').
import * as cardsLib from './Pyramid';



import './pyramid.scss';

const {Pyramid} = cardsLib;


const namePrefix = {
  0: 'king',
  1: 'ace',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: 'jack',
  12: 'queen',
};
let cardMappings = {};

const suits = {
  0: 'clubs',
  1: 'diamonds',
  2: 'hearts',
  3: 'spades'
};

for (let i = 0; i < 52; i++) {
  const prefix = namePrefix[i % 13];
  const suit = suits[Math.floor(i / 13)];

  cardMappings[i] = `${prefix}_of_${suit}`;
}

console.log({ cardMappings });

// const cardMappings = {
//   0: 'king_of_clubs',
//   1: 'ace_of_clubs',
//   2: '2_of_clubs',
//   3: '3_of_clubs',
//   4: '4_of_clubs',
//   5: '5_of_clubs',
//   6: '6_of_clubs',
//   7: '7_of_clubs',
//   8: '8_of_clubs',
//   9: '9_of_clubs',
//   10: '10_of_clubs',
//   11: 'jack_of_clubs',
//   12: 'queen_of_clubs',
//   13: 'king_of_diamonds',

//   14: 'ace_of_diamonds',
//   15: '2_of_diamonds',
//   16: '2_of_diamonds',
//   17: '2_of_diamonds',
//   18: '2_of_diamonds',
//   19: '2_of_diamonds',
//   20: '2_of_diamonds',
//   21: '2_of_diamonds',
//   22: '2_of_diamonds',
//   23: '2_of_diamonds',
//   24: '2_of_diamonds',
//   25: '2_of_diamonds',
//   26: '2_of_diamonds',
//   27: '2_of_diamonds',
//   28: '2_of_diamonds',
//   29: '2_of_diamonds',
//   30: '2_of_diamonds',

// };


let pyramid;
let game;

const getPyramidString = () => {
  const pyramidArray = game.pyramidArray;

  let result = '';
  for (let rowIndex = 0; rowIndex < 7; rowIndex++) {
    let str = '  '.repeat(7 - rowIndex);
    const row = pyramidArray[6 - rowIndex];

    for (let colIndex = 0; colIndex < 7; colIndex++) {
      const cardVal = row[colIndex] % 13;
      str += `   ${cardVal === -1 ? '' : cardVal}`.substr(-3);

      // if (cardVal !== -1) {
      //     str += `   ${cardVal}`.substr(-3);
      // }
    }
    result += `${str}\n`;
  }

  return result;

}

const getDrawnCardsString = () => {
  const drawnCards = game.drawnCards;
  const lastIndex = drawnCards.length - 1;

  let str = '';
  for (let i = 0; i < 2; i++) {

    str += `   ${drawnCards[lastIndex - i] % 13}`;
  }
  return str;
}

export default class PyramidComponent extends React.Component {

  //https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
  constructor(props) {
    super(props);

    console.log({
      Pyramid
    });

    game = Pyramid.startNewGame(1);
    console.log(game);


    const str = getPyramidString();
    // console.log(str);


    console.log(str);

    // get shown cards. top two drawn cards.
    // term(str);

    const drawnCardsString = getDrawnCardsString();

    console.log(drawnCardsString);

    const pyramidArray = game.pyramidArray;


    this.state = {
      pyramidArray,
      drawnCards: game.drawnCards,
      clickedCards: [],
      // type: 'simple'
    }
  }


  // https://www.w3schools.com/html/tryit.asp?filename=tryhtml5_draganddrop
  // https://code.google.com/archive/p/vector-playing-cards/downloads

  /**
   * Only display the top 3
   * @param {*} drawnCards 
   * @returns 
   */
  drawnCardsDiv(drawnCards) {

    // let visibleCards = drawnCards.reverse().slice(drawnCards.length - 2);
    let visibleCards = drawnCards.reverse().slice(0, 2);

    // visibleCards = drawnCards;

    return visibleCards.map((card, cellIndex) => {
      return (
        <span key={cellIndex}
          style={{
            padding: '10px',
            // border: "1px solid black" 
          }}>
          {this.cardImg(card, cellIndex, -1)}
          {/* {card} */}
        </span>
      )
    })
  };

  /**
 * Called whenever the state gets an update. This includes 
 * whenever a move is detected.
 * @param {*} prevProps 
 * @param {*} prevState 
 * @returns 
 */
  async componentDidUpdate(prevProps, prevState) {
    console.log(`componentDidUpdate`, { prevState }, this.state);

    if (this.state.clickedCards.length === 2) {
      // try to match and clear the current clicked cards list.

      this.setState({ clickedCards: [] });
    }
  }

  isCardClicked(rowIndex, columnIndex) {

    for (let clickedCard of this.state.clickedCards) {
      if (rowIndex === clickedCard[0] && columnIndex === clickedCard[1]) {
        return true;
      }
    }
    return false;
  }


  doMatchCards(clickedCards) {
    // update state of array 

    console.log(`doMatchCards`, clickedCards);

    const matches = clickedCards.map(clickedCard => {
      // drawn cards
      if (clickedCard[1] === -1) {
        return clickedCard[0] === 0 ? 1 : 0;
      }
      return clickedCard[1] + 2;
    });

    const result = game.doMatch(matches[0], matches[1]);
    console.log(`doMatchCards`, result, matches);

    this.setState({
      pyramidArray: game.pyramidArray,
      drawnCards: game.drawnCards,
      clickedCards: [],
    });
  }

  //https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
  //https://www.w3schools.com/html/tryit.asp?filename=tryhtml5_draganddrop
  cardImg(card, rowIndex, columnIndex) {
    let cardName = cardMappings[card];

    if (cardName) {
      let isCardClicked = this.isCardClicked(rowIndex, columnIndex);

      const className = isCardClicked ? `card-clicked` : `card-not-clicked`;

      return (<img
        className={`${className}`}
        style={{ width: '80px' }}
        onClick={() => {
          console.log({ rowIndex, columnIndex });
          let clickedCards = [...this.state.clickedCards, [rowIndex, columnIndex]];

          if (clickedCards.length === 2) {
            this.doMatchCards(clickedCards);
          } else {
            this.setState({
              clickedCards
            });
          }


        }}
        onDragOver={(evt) => {
          // console.log(`dragOver`,evt);
          evt.preventDefault();
        }}
        onDrop={(evt, target) => {
          // console.log(evt);
          console.log(evt.target, cardName, target);
        }}
        src={`/data/cards/${cardName}.png`}></img>);
      // return cardName;
    } else {
      if (card === -1) {
        return (
          <img
            style={{
              opacity: 0,
              width: '80px'
            }}
            src={`/data/cards/ace_of_spades.png`}></img>
        )
        // return (<span style={{[`minWidth`]: '15px', display: 'inline-block'}}></span>);
      } else {
        return card;
      }
    }
  }

  /**
   * auto padding
   * width
   * @param {*} row 
   * @param {*} rowIndex 
   * @param {*} paddingCount 
   * @returns 
   */
  pyramidRow(row, rowIndex) {

    return row.map((card, cellIndex) => {
      return (
        <span key={cellIndex} style={{ padding: '10px' }} >
          {this.cardImg(card, rowIndex, cellIndex)}
        </span>
      )
    });
  }

  pyramidArrayDiv(pyramidArray) {
    // add padding by pushing with concat

    console.log({ pyramidArray });
    let result = pyramidArray.reverse().map((cardRow, i) => {

      let paddingLeft = 400 - i * 50;

      // const paddingCount = 7 - i;
      // const padding = [-1, -1, -1, -1, -1, -1, -1].slice(0, paddingCount);
      // let cardRowWithPadding = [...padding, ...cardRow];
      return (<div style={{ 
        paddingLeft: `${paddingLeft}px` 
        }} key={`row-${i}`}>
        {this.pyramidRow(cardRow, i)}
      </div>)
    });
    console.log(result);
    return result;
    // for (let i = 0; i++; i < 7) {
    //   const row = pyramidArray[6 - i];

    // }
  }

  drawCard() {
    game.draw();

    this.setState({
      pyramidArray: game.pyramidArray,
      drawnCards: game.drawnCards
    })
  }

  render() {

    console.log(this.state.pyramidArray);

    const array = this.pyramidArrayDiv(this.state.pyramidArray);

    // const drawnCardsString = getDrawnCardsString();
    const drawnCards = this.state.drawnCards;

    const drawnCardsDiv = this.drawnCardsDiv(drawnCards);

    return (<div>
      Pyramid
      <div style={{ 'padding': '20px' }}>
        {array}

        <div>
          {drawnCardsDiv}
        </div>

        <button onClick={(el) => {
          this.drawCard();
          // console.log(this, el, this.newBoard(el));
        }}>Draw Card</button>

      </div>

    </div>)

  }
}
