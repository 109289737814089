import spriteImg from './src/images/sprite.js';

import miscInit from './src/misc/misc';



// 
// index.css:1 Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of "text/css". Strict MIME type checking is enforced for module scripts per HTML spec.
// import './index.css';
// 9

let initialized = false;

export default () => {
    console.log('init index.js');

    // only initialize once.
    if (initialized) {
        return;
    }
    initialized = true;

    const gameEl = document.getElementById('js-solitaire');
    const dealPileEl = document.getElementById('js-deck-pile');
    const dealEl = document.getElementById('js-deck-deal');
    const finishContainerEl = document.getElementById('js-finish');
    const deskContainerEl = document.getElementById('js-board');
    const deckPileEl = document.getElementById('js-deck-pile');
    const resetEl = document.getElementById('js-reset');



    const state = {
        // clubs (♣), diamonds (♦), hearts (♥) and spades (♠)
        types: ['c', 'd', 'h', 's'],
        // 0 (black), 1 (red)
        colors: { 'c': 0, 'd': 1, 'h': 1, 's': 0 },
        cards: [
            // { el: null, type: 's', number: 0, facingUp: false }, {...}
        ],
        // dealer deck
        deal: {
            pile: {
                el: null,
                cards: [/* 0, 1, ... */]
            },
            deal: {
                el: null,
                cards: [/* 0, 1, ... */]
            },
        },
        // finish deck
        finish: [
            // { el: null, cards: [ 0, 1, ... ] }, {...}
        ],
        // playing desk
        desk: [
            // { el: null, cards: [ 0, 1, ... ]}, {...}
        ],
        // move target
        target: null,
        // moving
        moving: {
            card: {},
            element: null,
            index: -1,
            capture: false,
            container: {
                cards: [/* 0, 1, ... */]
            },
            target: null,
            origin: {},
            offset: {
                x: 0,
                y: 0,
            },
            destinations: []
        }
    };







    /**
     * 
     */
    const createAllCards = () => {
        // create all cards
        for (let i = 0; i < 4; i++) {
            for (let j = 1; j <= 13; j++) {
                const el = document.createElement('div');
                el.classList.add(
                    'card',
                    `card--${state.types[i]}-${j}`,
                    'card--back'
                );

                state.cards.push({
                    el: el,
                    type: state.types[i],
                    number: j,
                    facingUp: false
                });
            }
        }
    }

    // create aces decks
    const createAces = () => {
        for (let i = 0; i < 4; i++) {
            const el = document.createElement('div');
            el.classList.add(
                'aces',
                `aces--${i}`
            );
            state.finish.push({
                el: el,
                cards: []
            });
            finishContainerEl.appendChild(el);
        }
    }

    // create desk decks

    const createDeskDecks = () => {
        for (let i = 0; i < 7; i++) {
            const el = document.createElement('div');
            el.classList.add(
                'seven',
                `seven--${i}`
            );
            state.desk.push({
                el: el,
                cards: []
            });
            deskContainerEl.appendChild(el);
        }
    }


    function initSolitaire() {

        console.log(`initSolitaire v1.0`);


        const misc = miscInit(state, gameEl);





        createAllCards();
        createAces();
        createDeskDecks();



        dealPileEl.onclick = misc.restartDeal;

        // resetEl.onclick = () => {misc.resetGame()};
        resetEl.onclick = misc.resetGame


        misc.resetGame();
    }

    // window.onload = initSolitaire;

    initSolitaire();
}