// https://en.wikipedia.org/wiki/Pulses_per_quarter_note

import React from 'react';
import Soundfont from 'soundfont-player';
import FilePath from '../../../services/FilePath';
import MidiHelper from '../../../services/midi/MidiHelper';
import MidiSummary from '../../midi/MidiSummary';
import MidiTrack from '../../midi/MidiTrack';
import SoundfontPlayerHelper from './SoundfontPlayerHelper';
import SoundfontPlayerHelper2 from './SoundfontPlayerHelper2';
import Form from 'react-bootstrap/Form';

// https://github.com/danigb/sample-player/blob/master/lib/player.js
// TODO I may need to create my own fork of Soundfont player.

// var ac = new AudioContext();
// can only be created after some user input.
let audioContext = new AudioContext();

const masterVolumeNode = audioContext.createGain();

// treat gainNode as main context.
let ac = audioContext;

masterVolumeNode.connect(audioContext.destination);
masterVolumeNode.gain.value = 1;


// instrument	note count	start	stop	duration
// distortion guitar #30	676	0	0	0
// overdriven guitar #29	273	0	0	0
// string ensemble 1 #48	384	0	0	0
// string ensemble 1 #48	222	0	0	0
// power kit #16	559	0	0	0


const noteMapping = {
    'q': 60,
    'w': 62, //D4
    'e': 64,
    'r': 65,// f4
    't': 67,
    'y': 69, // A4 concert pitch
    'u': 71,
    'i': 72,
    // 'e': 68,
    // 'e': 69,

};




let instruments = {
    'acoustic_grand_piano': null,
    'overdriven_guitar': null,
    // 'distortion_guitar': null,
    'trumpet': null,
};

const preloadedInstruments = [
    'acoustic_grand_piano'
];


const fallback = {
    'distortion_guitar': 'overdriven_guitar'
};

const midiInstrumentMappings = {
    0: 'acoustic_grand_piano',
    30: 'distortion_guitar',
};

const defaultInstrumentKey = 'acoustic_grand_piano';

// TODO famitracker midi file.
export default class SoundfontPlayerDemo extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            header: props.header,
            loadingInstruments: false,
            instrument: defaultInstrumentKey,
            currentKey: '',
            file: null,
            midiLoaded: 0,
            midi: null,
            volume: 50
        }
    }


    async componentDidUpdate(prevProps, prevState) {

        try {
            if (prevState.file !== this.state.file) {
                const midi = await MidiHelper.loadTrackFromFile(this.state.file);
                this.setState({ midi, midiLoaded: Date.now() });
            }
    
            if (prevState.volume !== this.state.volume) {
                masterVolumeNode.gain.value = Math.min(1,this.state.volume / 100);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }

    }

    getInstrumentKeyFromNum(num) {
        if (midiInstrumentMappings[num]) {
            return midiInstrumentMappings[num];
        }
        // acoustic piano.
        return midiInstrumentMappings[0];
    }

    async getInstrumentByNum(num, useExisting = true) {
        return this.getInstrument(this.getInstrumentKeyFromNum(num), useExisting)
    }

    async getInstrument(key, useExisting = true) {
        let instrumentKey = key;
        try {
            if (instruments[instrumentKey] === undefined) {
                instrumentKey = fallback[instrumentKey] || midiInstrumentMappings[0];
            }
            if (!useExisting) {
                return await Soundfont.instrument(ac, await FilePath.getPath(`/data/soundfonts/${instrumentKey}-mp3.js`));
            }
            if (!instruments[instrumentKey]) {
                const ac = this.getAudioContext();
                instruments[instrumentKey] = await Soundfont.instrument(ac, await FilePath.getPath(`/data/soundfonts/${instrumentKey}-mp3.js`));
            }
            return instruments[instrumentKey]
        } catch (err) {
            console.error(`Failed to fetch instrument ${instrumentKey}`);
            throw err;
        }

    }

    async componentDidMount() {
        masterVolumeNode.gain.value = Math.min(1, this.state.volume / 100);
    }

    //https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    getMsPerTick({ bpm, ppq }) {
        return 60000 / (bpm * ppq);
    }

    async playNote(note, {
        time,
        duration,
        gain,
        attack,
        decay,
        sustain,
        // adsr: [attack,decay,sustain,release]
    } = {}) {
        if (this.state.instrument) {
            // const instrument2 = instruments[`trumpet`];

            const instrument = await this.getInstrument(this.state.instrument);

            // const instrument = instruments[this.state.instrument];

            // TODO adsr envelope
            instrument.play(note, ac.currentTime, { duration: .5 });
            // console.log(`play`, note);
            // instrument.play(note);

            // instrument2.play('D4');
        }
        // const instrumentName = 'clavinet';
        // const instrumentName = 8;
        // const instrumentName = 1;

        // const instrument = await this.getInstrument(instrumentName);
        // SoundfontPlayer.instrument(ac, 'clavinet').then(function (clavinet) {
        // clavinet.play('C4')
        //   })
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    // assumes single file.
    // TODO allow multiple files
    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        this.setState({ file }); /// if you want to upload latter
    }

    // TODO keyup to stop note.
    handleKeyDown(e) {
        const { key, keyCode } = e;
        this.setState({
            ...this.state,
            currentKey: key
        });

        if (noteMapping[key]) {
            this.playNote(noteMapping[key]);
        }

        // if (key === 'q') {
        //     this.playNote('C4');
        // }
    }

    getAudioContext() {
        if (!ac) {
            ac = new AudioContext();
        }
        return ac;
    }

    async playAllTracks() {
        // midiInstrumentMappings
        const { midi } = this.state;
        if (midi) {

            //https://stackoverflow.com/questions/43454215/web-audio-api-stop-all-scheduled-sounds-from-playing
            // ac.close();
            // ac = null;
            // this.getAudioContext();
            // SoundfontPlayerHelper2.stopAll();

            // const ac = this.getAudioContext();
            // const getInstrument = async (instrumentNum) => {
            //     return this.getInstrumentByNum(instrumentNum, false);
            // }

            const mainNode = masterVolumeNode;

            return SoundfontPlayerHelper2.playMidi({ midi, ac, mainNode });

        }
    }

    async playTrack1() {
        const { instrument: instrumentKey, midi } = this.state;
        console.log('playTrack1', { instrumentKey, midi, ac });
        if (instrumentKey && midi) {
            // audio context not allowed to start.
            // use global audio context.
            // works better.
            // const ac = this.getNewAudioContext();

            const track = midi.tracks[0];
            console.log(track, midi);
            // const song = MidiHelper.getSongFromMidiTrack(midi,track);

            const instrument = await this.getInstrument(instrumentKey);

            return SoundfontPlayerHelper.playTrack({
                midi,
                ac,
                track,
                instrument
            });
        }
    }

    // https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    render() {

        const { midi } = this.state;

        let instrumentSelect = (<div>Loading</div>);

        //  TODO smoother instrument loading. Load piano first and then load others.
        if (!this.state.loadingInstruments) {
            const options = Object.keys(instruments).map(el =>
                <option key={el} value={el}>{el || el}</option>
            );


            instrumentSelect = (
                <select name="instrument" id="instrument"
                    value={this.state.trackSelectVal}
                    onChange={(event) => this.handleChange(event)}
                >
                    {options}

                </select>
            );
        }

        if (this.state.instrument) {

        }

        const volumeStyle = {'width': '500px'}

        return (
            <div>
                <div>SoundfontPlayer</div>

                {/* TODO capture input and have a piano keyboard visual */}
                <input
                    onChange={() => { }}
                    value={this.state.currentKey}
                    onKeyDown={(e) => this.handleKeyDown(e)}></input>

                <div style={volumeStyle}>
                    <Form.Label>Volume {this.state.volume}</Form.Label>
                    <Form.Range 
                    name="volume"
                    onChange={(e) => { this.handleChange(e) }}
                    value={this.state.volume}
                     />
                </div>

                {instrumentSelect}

                <div>{this.state.instrument}</div>

                <input id="myInput"
                    type="file"
                    accept="audio/mid"
                    // accept=".mid,.midi"
                    ref={(ref) => this.upload = ref}
                    onChange={this.onChangeFile.bind(this)}
                />

                {midi && (
                    <div>
                        <button type="button" onClick={() => this.playTrack1()}>Play Track 1</button>
                        <button type="button" onClick={() => this.playAllTracks()}>Play All Tracks</button>

                    </div>
                )}
                <div>

                </div>
                <div>{this.state.midiLoaded}</div>

{/* changing the key forces a rerender? */}
{/* https://linguinecode.com/post/4-methods-to-re-render-react-component
setting the key makes sense here because it is a new file that we want to summarize whenever a new midi file
is loaded.
*/}
                <MidiSummary key={`summary-${this.state.midiLoaded}`} midi={midi}></MidiSummary>
            </div>
        )
    }
}