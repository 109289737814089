import React from 'react';
import MidiHelper from '../../../services/midi/MidiHelper';


const trackHeaders = [
    'track_num',
    'track_start',
    'track_end',
    'track_duration',
    'note_count',
    'inst_name',
    'inst_num',
];


/**
 * Summarizes the main midi information.
 * Information above the track level stored in header.
 */
export default class MidiSummaryTable extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            midi: props.midi
        }
    }

    /**
     * 
     * @param {*} track 
     */
    getTrackRowArray(track) {
        const {notes,pitchBends,instrument,controlChanges} = track;
        const {number,family, name, percussion} = instrument;

    }

    //https://github.com/Tonejs/Midi
    render() {
        const {midi} = this.state;

        const {header, tracks} = midi;
        const {ppq, tempos, timeSignatures} = header;
        const uniqueTempoCount = tempos.length;
        const tempo1 = tempos[0];
        const {bpm,ticks} = tempo1;

        const mspertick = MidiHelper.getMsPerTick({bpm,ppq});
        const tempoName = MidiHelper.getTempoName(bpm,ppq);

        return (
            <div>
                <div>
                    <div>
                    ppq = Pulses Per Quarter note (defined by header)

                    </div>
                    <div>
                    bpm = beats per midi (defined in tempos as a part of header)

                        </div>
                        <div>
                        ticks = smallest unit of time for midi?
                        </div>
                </div>

                <table className='russ-table'>
                    <thead>
                        <tr>
                        <th>
                            tempos
                        </th>
                        <th>
                            tempo name
                        </th>
                        <th>
                            bpm
                        </th>
                        <th>
                            ms / tick
                        </th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>{uniqueTempoCount}</td>
                            <td>{tempoName}</td>
                            <td>{Math.round(bpm)}</td>
                            <td>{mspertick.toFixed(2)} (60000 / ({Math.floor(bpm)} bpm * {Math.floor(ppq)} ppq))</td>

                        </tr>
                    </tbody>
                </table>
        
            </div>
        )
    }
}