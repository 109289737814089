// import { Instrument } from "@tonejs/midi/dist/Instrument";

import MidiHelper from "../../../services/midi/MidiHelper";
import Instrument from "./Instrument";

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Advanced_techniques

/**
 * Using instrument samples from wav loaded in.
 * 
 */
export default class SoundfontPlayerHelper2 {



    static getInstrumentNumberFromTrack(track) {
        const { instrument } = track;
        if (!instrument) {
            return 0;
        }
        return instrument.number;
    }

    static getSimplifiedTracks(
        midi
    ) {

        const { tracks: originalTracks } = midi;

        let tracks = originalTracks.filter(track => {
            const { notes } = track;

            if (!notes || notes.length === 0) {
                return false;
            }
            return true;
        });

        tracks = tracks.map(track => {
            const instrumentNum = this.getInstrumentNumberFromTrack(track);
            return {
                instrumentNum,
                notes: MidiHelper.getNotesFromMidiTrack(midi, track)
            }
        });


        return tracks;

        // pitch: midi,
        // startTime: realTimeStart, // noteStartTime,
        // endTime: (realTimeStart + realTimeDuration),

    }


    static async sleep(timeout = 5000) {
        return new Promise(r => {
            setTimeout(r, timeout);
        })
    }

    /**
     * TODO combine with existing playTrack;
     * @param {*} track 
     * @param {*} ac - audio context that instrument is loaded onto. 
     */
    static async playTrackSimplified(track, ac) {
        const { instrument, notes } = track;

        // this.activeInstruments.push(instrument);

        const currentTime = ac.currentTime;

        // do intial load and then each on ended load the next note.
        // At 5 twinkle twinkle gets out of sync waiting on the next note.
        // 10 sounds okay.
        // const noteBufferSize = 10;
        // 50 is too high
        // 20 still seems a little too high.
        const noteBufferSize = 10;


        const notesLength = notes.length;
        let i = 0;

        const handleNextNote = () => {
            // console.log(`handleNextNote`);
            if (i >= notesLength) {
                console.log('no next note');
                return;
            }
            const note = notes[i];
            const { startTime, endTime, pitch } = note;
            const duration = endTime - startTime;
            const playTime = currentTime + startTime
            // console.log(`play`, {pitch,currentTime,startTime,duration});

            // something wrong with the slice loop here?
            // maybe just an index would be simpler.
            // console.log(playTime, i, notesLength);
            instrument.start(pitch, currentTime + startTime, { duration });
            i++;
        }

        // same loaded instrument interference.
        // only one onended allowed?
        // instrument.onended = handleNextNote;
        // instrument object should not be shared between them.
        instrument.on('ended', handleNextNote);

        while (i < notesLength) {

            let j = 0;
            while (j < noteBufferSize && i < notesLength) {

                const note = notes[i];
                const { startTime, endTime, pitch } = note;
                const duration = endTime - startTime;
                const playTime = currentTime + startTime
                // console.log(`play`, {pitch,currentTime,startTime,duration});

                // something wrong with the slice loop here?
                // maybe just an index would be simpler.
                // console.log(playTime, i, notesLength);
                instrument.start(pitch, currentTime + startTime, { duration });

                // instrument.onended = (e) => {
                //     console.log(`ended`,e)

                // }
                // console.log(event);

                // //https://developer.mozilla.org/en-US/docs/Web/API/AudioScheduledSourceNode/ended_event
                // event.onended = (e => {
                //     console.log(`ended`,e)
                // })
                i++;
                j++;
            }
            break;
            // do sleep here.
            // await this.sleep(sleepTime);

            // let notesSlice = notes.slice(0,noteBufferSize);

            // for (let {startTime,endTime,pitch} of notesSlice) {

            // }

        }

    }


    static async playTracks(tracks, ac) {
        tracks.forEach(track => {
            this.playTrackSimplified(track, ac);
        }) 
        // return 
    }

    static async getInstrument(instrumentNum, audioContext, mainNode) {
        return Instrument.loadInstrument(audioContext, instrumentNum, mainNode);
    }



    // TODO stop all instruments currently playing.
    static async stopAll() {
        // this.stopTracks();
    }
    /**
     * 
     * ac - Audio context
     * mainNode - main node to connect through.
     * @param {*} param0 
     */
    static async playMidi({
        midi,
        ac,
        mainNode: mainNodeArg
    }) {

        // default main connect node to audio context
        const mainNode = mainNodeArg || ac;
        const tracks = this.getSimplifiedTracks(midi);
        console.log(`playMidi`, tracks);

        for (let track of tracks) {
            let instrument = await this.getInstrument(track.instrumentNum, ac, mainNode);
            // mainNode.connect();
            track.instrument = instrument;
        }
        console.log(`loaded instruments`, tracks);


        // load all instruments for each track that has notes.

        // I may need to rearrange the notes in order some tracks start later.


        await this.playTracks(tracks, ac);

    }



    static async playTrack({
        midi,
        track,
        instrument,
        ac
    }) {

        const song = MidiHelper.getSongFromMidiTrack(midi, track);
        const { notes } = song;
        // const note = notes[0];
        // const {pitch, program, startTime, endTime} = note;

        // // this.playNote(pitch);
        // // TODO adsr envelope
        // const duration = endTime - startTime;
        // instrument.play(pitch, ac.currentTime + startTime, { duration });

        // const events = notes.slice(0,1000).map(note => {
        //     const {pitch, program, startTime, endTime} = note;
        //     const duration = endTime - startTime;
        //     return {
        //         time: startTime,
        //         note: pitch,
        //         duration
        //     }
        // });

        // // console.log({events});
        // instrument.schedule(ac.currentTime, events);

        // https://www.npmjs.com/package/audio-loader
        // https://www.npmjs.com/package/sample-player

        // sample player is the main handler of schedule
        // https://www.npmjs.com/package/sample-player#player.play

        // works the first time?
        // Still acting strangely

        // can't just load in the entire song.
        // buffer in groups of 10? / 100
        const currentTime = ac.currentTime;
        for (let note of notes.slice(0, 100)) {
            const { pitch, program, startTime, endTime } = note;

            // this.playNote(pitch);
            // TODO adsr envelope
            const duration = endTime - startTime;
            // // TODO adsr envelope
            // Or schedule events at a given time
            // clavinet.schedule(ac.currentTime + 5, [ { time: 0, note: 60}, { time: 0.5, note: 61}, ...])

            // console.log({pitch,startTime,endTime});

            //https://www.npmjs.com/package/@baocang/sample-audio-player
            // duration is ignored?
            console.log(`play`, { pitch }, currentTime, duration, currentTime + startTime);

            // not handling audio context properly.
            instrument.start(pitch, currentTime + startTime, { duration });
        }

        // TODO correct ac.currentTime
        setTimeout(async () => {
            for (let note of notes.slice(100, 200)) {
                const { pitch, program, startTime, endTime } = note;

                // this.playNote(pitch);
                // TODO adsr envelope
                const duration = endTime - startTime;
                // // TODO adsr envelope
                // Or schedule events at a given time
                // clavinet.schedule(ac.currentTime + 5, [ { time: 0, note: 60}, { time: 0.5, note: 61}, ...])

                // console.log({pitch,startTime,endTime});

                //https://www.npmjs.com/package/@baocang/sample-audio-player
                // duration is ignored?
                console.log(`play`, { pitch }, currentTime, duration, ac.currentTime + startTime);

                // not handling audio context properly.
                instrument.start(pitch, currentTime + startTime, { duration });
            }
        }, 10 * 1000);

    }
}