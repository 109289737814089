
/**
 * TODO define decay time etc.
 */
export default class NoteHelper {



    // each channel needs its own audio context?
    // No that will only play one audio context at a time.

    //https://stackoverflow.com/questions/32170472/js-audiocontext-oscillator-play-more-than-one-note-at-the-same-time-polyphony

    // I am pretty sure unique osciallators are being used here.

    constructor() {
        // let audioCtx = audioContext;
        // if (!audioCtx) {
        //     audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        // }
        // this.audioContext =  new (window.AudioContext || window.webkitAudioContext)(); // NoteHelper.getAudioContext();

        this.audioContext = NoteHelper.getAudioContext();
        this.id = NoteHelper.getNextId();

        const oscillator = this.getOscillator();
        this.decayTime = 0.1;
        const time =  this.audioContext.currentTime;

        let gainNode = this.getGainNode();
        gainNode.connect(this.audioContext.destination);
        gainNode.gain.setTargetAtTime(0, time , 0);
        oscillator.connect(gainNode);
        oscillator.start();
    }

    static getNextId() {
        if (!this.id) {
            this.id = 0;
        }
        this.id++;
        return this.id;
    }

    static getAudioContext() {
        // return this.audioContext;
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        }
        return this.audioContext;
    }

    getOscillator() {
        if (!this.oscillator) {
            this.oscillator = this.audioContext.createOscillator();
            this.oscillator.type = 'square';
        }
        return this.oscillator;
    }

    getGainNode() {
        if (!this.gainNode) {
            this.gainNode = this.audioContext.createGain();
        }
        return this.gainNode;
    }

    playStop() {
        const {audioContext: audioCtx} = this;
        // this.oscillator.stop();
        const time = audioCtx.currentTime;
        const gainNode = this.getGainNode();
        gainNode.gain.setTargetAtTime(0, time + this.decayTime , 0);

        console.log(`playStop`,gainNode);

    }

    //https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Advanced_techniques
    //https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createPeriodicWave
    //  customWaveform = audioContext.createPeriodicWave(cosineTerms, sineTerms);

    //https://www.sitepoint.com/using-fourier-transforms-web-audio-api/
    //https://github.com/modosc/wavetable-synth
    //https://www.youtube.com/watch?v=tgamhuQnOkM&t=766s
    //https://shearspiremedia.com/projects/2019/04/01/accessible-web-audio-api-organ/
    //https://makepages.com/organtest/organwavebank.js

    playStart(freq = 400) {
        const {audioContext: audioCtx} = this;
        const oscillator = this.getOscillator();
        let gainNode = this.getGainNode();
        let maxVolume = .05;

        const time = audioCtx.currentTime;
        oscillator.frequency.setValueAtTime(freq, time); // value in hertz
        gainNode.connect(audioCtx.destination);
        // play and wait for end.
        gainNode.gain.setTargetAtTime(maxVolume, time , 0);
        // oscillator.connect(gainNode);
        // oscillator.start();

        console.log(`playStart`, this.id);

        return oscillator;
    }

    playBounce(freq = 400) {
        const {audioContext: audioCtx} = this;
        let maxVolume = .05;
        const oscillator = audioCtx.createOscillator();
        oscillator.type = 'square';
        const time = audioCtx.currentTime;
        oscillator.frequency.setValueAtTime(freq, time); // value in hertz
        let gainNode = audioCtx.createGain();
        console.log({oscillator, gainNode});
        gainNode.connect(audioCtx.destination);
    
        //https://developer.mozilla.org/en-US/docs/Web/API/AudioParam/setTargetAtTime
        // setTargetAtTime(target, startTime, timeConstant)
        gainNode.gain.setTargetAtTime(maxVolume, time , 0);
        gainNode.gain.setTargetAtTime(0, time + .01 , 0);
        oscillator.connect(gainNode);
    
        oscillator.start();
    }

}