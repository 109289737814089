import React from 'react';

import TetrisController from './../../services/tetris/TetrisController';

let tc = new TetrisController();


let c,ctx;

let loopInterval;
let loopCount = 0;

const blockSize = 25;
const blockWidth = 5;
const blockHeight = 15;

const centerBlock = Math.floor(blockWidth / 2);


// const width = 500;
const width = blockSize * blockWidth;

const height = blockSize * blockHeight;
let nextLoop;

// 0 index
// const blockWidth = (width / blockSize);



// ctx.canvas.width  = 500;
// ctx.canvas.height = 600;


export default class Tetris extends React.Component {


    
    constructor(props) {
        super(props);

        this.state = {
            loopms: 50

        }


        this.board = [];
        this.clearedLineCount = 0;
        this.shapesCount = 0;
    }


    spawnShape() {
        if (this.currentShape) {
            return false;
        }


        if (this.shapesCount  % 2 === 0) {
        // this.spawned = true;

            this.currentShape = [
                centerBlock,0,'line',0 // line in default position
            ];

        } else {
            this.currentShape = [
                centerBlock,0,'L',0 // line in default position
            ];
        }
        this.shapesCount++;

    }


    drawTestShapes() {
        const testShapes = [
            [0,0,'red'],
            [0,1,'blue'],
            [0,2,'yellow'],

            // square
            [0,3,'orange'],
            [0,4,'orange'],
            [1,3,'orange'],
            [1,4,'orange'],
        ];

        this.renderArray(testShapes);

    }


    drawTestGrid() {
        ctx.beginPath();

        // horizontal
        let y = blockSize;
        while (y < height) {
            ctx.moveTo(0, y);
            ctx.lineTo(width, y);
            y += blockSize;
        }

        // vertical
        let x = blockSize;
        while (x < width) {
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            x += blockSize;
        }


        ctx.stroke();
    }

    getArrayFromShape([x,y,shapeType,positionIdx]) {

        // rotational point should be in the center.
        // probably defining a single array and then a set of arrays
        // to add to the original array will be easier than this mess.
        const ary = [];
        if (shapeType === 'line') {
            let xChange = 0;
            let yChange = 0;
            if (positionIdx % 2 === 0) {
                yChange = -1;
            } else {
                xChange = -1;
            }

            let i = 0;
            while (i < 4) {
                // start at y and y -1 to go up.
                ary.push([x + (i * xChange),y + (i * yChange),'red']);
                i++;
            }

        } else if (shapeType === 'L') {

            if (positionIdx === 0 ) {
                // vertical default position
                ary.push([x,y,'orange']);
                ary.push([x,y - 1,'orange']);
                ary.push([x,y - 2,'orange']);
                // +1 to x for j
                ary.push([x - 1, y - 2,'orange']);
            } else if (positionIdx === 1) {
                // horizontal with y down one.
                ary.push([x,y,'orange']);
                ary.push([x - 1, y,'orange']);
                ary.push([x - 2, y,'orange']);
                ary.push([x - 2, y + 1,'orange']);
            } else if (positionIdx === 2) {
                // vertical with L shape
                ary.push([x,y,'orange']);
                ary.push([x ,y +1,'orange']);
                ary.push([x,y +2,'orange']);
                ary.push([x + 1, y +2 ,'orange']);
            } else if (positionIdx === 3) {
                // horizontal with y up
                ary.push([x, y, 'orange']);
                ary.push([x + 1, y,'orange']);
                ary.push([x+ 2, y,'orange']);
                ary.push([x + 2, y - 1,'orange']);
            }



            console.log(`add l shape`);
            // ary.push([x + 1,y,'orange']);
            // ary.push([x + 2,y,'orange']);
            // ary.push([x + 2,y + 1,'orange']);


            // let i = 0;
            // while (i < 4) {
            //     // start at y and y -1 to go up.
            //     ary.push([x + (i * xChange),y + (i * yChange),'red']);
            //     i++;
            // }
        }
        return ary;
    }

    renderArray(ary) {
        ary.forEach(([xPos,yPos,color]) => {
            const xReal = blockSize * xPos;
            const yReal = blockSize * yPos;

            ctx.fillStyle = color;
            ctx.fillRect(xReal, yReal, blockSize, blockSize);
        });
    }

    renderCurrentShape() {
        if (this.currentShape) {
            const [x,y,shapeType,positionIdx] = this.currentShape;
            const ary = this.getArrayFromShape([x,y,shapeType,positionIdx]);
            this.renderArray(ary);
        }
    }

    renderGame() {
        this.renderCurrentShape();
    }

    clearCanvas() {
        ctx.clearRect(0, 0, width, height);
    }

    renderBoard() {
        this.renderArray(this.board);
    }

    /**
     * 
     * @param {*} shape 
     * @param {*} oldShape 
     * @returns 
     */
    checkCollision(shape, oldShape) {
        const board = this.board;
        const [originalX, originalY, shapeType, positionIdx] = shape;
        // const [x,y,shapeType,positionIdx];

        const ary = this.getArrayFromShape(shape);
        for (let [x,y] of ary) {
            if (y * blockSize >= height) {
                // end of current shape
                return [true, oldShape];
            }
            // cannot move that way but is not a collision.
            if (x < 0) {
                return [false, oldShape];
            }
            const collision = board.find(([boardX,boardY]) => boardX === x && boardY === y);
            if (collision) {
                // end of current shape
                return [true, oldShape];
            }
        }
        return [false, [originalX, originalY, shapeType, positionIdx]]

    }

    calculateXPosition(inputs, shape) {
        let i = 0;
        let deltaX = inputs.right - inputs.left;
        // let x = originalX + (inputs.right - inputs.left);
        const ary = this.getArrayFromShape(shape);

        for (let [originalX] of ary) {
            let newX = originalX + deltaX;
            if (newX < 0) {
                deltaX = (0 - newX) - 1;
            }
            if (newX > blockWidth - 1) {
                console.log({newX,blockWidth,deltaX});
                deltaX = (blockWidth) - newX;
                // x = blockWidth - 1;
            }
        }

        console.log({deltaX},shape[0]);

        return shape[0] + deltaX;

        // if (x < 0) {
            // x = 0;
        // }
        // if (x > blockWidth - 1) {
            // x = blockWidth - 1;
        // }
        // return x;
    }

    /**
     * just check the whole board every time.
     * 
     * I can optimize this by only checking places that
     * can change based on piece placement but really
     * there are other things that need to be optimized
     * first to make a difference.
     */
    checkCompletedLines() {
        let board = this.board;
//         0
// : 
// (3) [9, 14, 'red']
// 1
// : 
// (3) [9, 13, 'red']
// 2
// : 
// (3) [9, 12, 'red']
// 3
// : 
// (3) [9, 11, 'red']

        const clearedLines = [];
        for (let y = 0; y < blockHeight; y++) {
            let hasFullLine = true;
            for (let x = 0; x < blockWidth; x++ ) {
                let foundBlock = board.find(([blockX,blockY]) => blockX === x && blockY === y);
                if (!foundBlock) {
                    hasFullLine = false;
                    break;
                }
            }
            if (hasFullLine) {
                clearedLines.push(y);
            }
        }

        const final = [];
        for (let block of board) {
            let [x,y,color] = block;
            if (clearedLines.includes(y)) {
                continue;
            }
            const clearedLinesBelow = clearedLines.filter((clearedLineY) => {
                return clearedLineY > y;
            });
            y += clearedLinesBelow.length;
            final.push([x,y,color])
        }

        this.clearedLineCount += clearedLines.length;

        this.board = final;
    }

    gameLoop(loopCount) {
        this.yDelta = 0;
        this.xDelta = 0;

        if (loopCount % 5 === 0) {
            this.yDelta = 1;
        } else {
            this.yDelta = tc.input.down ? 1 : 0;
        }


        if (this.currentShape) {
            let inputs = tc.getActions();

            const [x,y,shapeType,positionIdx] = this.currentShape;

            const newPositionIdx = (positionIdx + inputs.rotate) % 4;

            let newX = this.calculateXPosition(inputs,this.currentShape);
            let newY = this.yDelta + y;

            // todo handle other actions.
            const [hasCollision,shape] = this.checkCollision(
                [newX, newY,shapeType,newPositionIdx],
                [x,y,shapeType,positionIdx],
            );
            if (hasCollision) {
                // newY -= 1;
                // add shape to the board;
                this.board = [...this.board,...this.getArrayFromShape(shape)];
                this.checkCompletedLines();
                this.currentShape = null;
            } else {
                this.currentShape = shape;
            }
        }

        if (this.board) {
            for (let [x,y] of this.board) {
                if (y < 0) {
                    this.gameover = true;
                    return false;
                }
            }
        }

        return true;
    }

    loop() {

        loopCount++;

        this.clearCanvas();
        this.drawTestGrid();
        // this.drawTestShapes();
        this.spawnShape();
        this.renderGame();
        this.renderBoard();

        const gameOver = !this.gameLoop(loopCount);

        if (gameOver) {
            // ANSWER: loop was being called twice.
            // TODO I don't really understand why this clear is necessary.
            // I should only be calling the next loop within this loop.
            // clearTimeout(nextLoop);
            // console.log(`gameOver`,gameOver,{loopCount});
            // setTimeout(() => {
            //     this.loop();
            // }, this.state.loopms);
        } else {
            nextLoop = setTimeout(() => {
                this.loop();
            }, Math.max(this.state.loopms, 20));

            this.setState({
                currentShape: this.currentShape,
                input: tc.input,
                clearedLineCount: this.clearedLineCount
            })
        }
        
                // // rerenders on state change.
                // this.setState({
                //     board: this.board
                // })


    }



    onStart() {

        c = document.getElementById("myCanvas");
        ctx = c.getContext("2d");
        ctx.canvas.width  = width;
        ctx.canvas.height = height;

        loopCount = 0;

        if (loopInterval) {
            clearInterval(loopInterval);
        }

        if (!this.isLoopCalled) {
            this.isLoopCalled = true;
            this.loop();

        }






        // play music

        // listen input on full screen.

    }


    handleAction(action) {
        if (action === 'LEFT') {
            // this.movingLeft = true;
            this.xDelta = -1;
            // this.yDelta = 0;
            if (this.currentShape) {
                this.currentShape[0] = this.currentShape[0] - 1;
                // const [x,y,shapeType,idx] = this.currentShape;
                // this.currentShape = [x - 1,y,shapeType,idx];
            }

        }
    }

    componentDidMount() {
            

        if (!this.handleController) {
            window.addEventListener("keyup", (e) => {tc.handleKeyup(e)});

            window.addEventListener("keydown", (e) => {tc.handleKeydown(e)});
            this.handleController = true;
            // tc.on('action', (action) => {
            //     this.handleAction(action);
            // });
        }



        this.onStart();


    }

    render() {

        const {currentShape, input,clearedLineCount} = this.state;

        return (
            <div>
                Tetris

                <canvas 
                
                id="myCanvas">

                </canvas>


                <button onClick={(e) => this.onStart()}>Start</button>


                <input 
                name="loopms" id="loopms"  value={this.state.loopms} onChange={(event) => this.handleChange(event)}
                />

<div>
                        Lines: {clearedLineCount}
                        </div>

{currentShape && (<pre>{JSON.stringify(currentShape)}</pre>
)}

{input && <pre>{JSON.stringify(input)}</pre>}

            </div>
        );
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
}