import ChessAppMain from './ChessAppMain';
import {
    BrowserRouter as Router,
    // Switch,
    Route,
    Routes,
    Navigate,
    Link,
    useParams,
    useLocation,
} from "react-router-dom";
// import * as chess  from '../../public/libs/chessboardjs-1.0.0/js/chessboard-1.0.0.js';
// TODO fix flashing effect whenever a move is made.
import * as chess  from '../libs/chessboardjs-1.0.0/js/chessboard-1.0.0.js';

{/* <script src="/libs/chessboardjs-1.0.0/js/chessboard-1.0.0.js"></script> */}
const ChessApp = (props) => {
    const location = useLocation();
    const params = useParams();

    console.log({location,params});

    //https://www.twilio.com/blog/react-choose-functional-components
    // const [count, setCount] = React.useState(0);

    // console.log({props});

    // search
// : 
// "?debug=1&test=1"


    return (
        <div>
            <span>{params.id}</span>
            <ChessAppMain location={location} gameId={params.id}></ChessAppMain>

        </div>
    )
}
export default ChessApp