
export default class FileLoader {

    /**
     * Get text or buffer data from file.
     * @param {} file 
     * @returns 
     */
    static async readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        })
    }

        /**
         * Get text or buffer data from file.
         * @param {} file 
         * @returns 
         */
         static async readTextFile(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
    
                reader.onload = () => {
                    resolve(reader.result);
                };
    
                reader.onerror = reject;
                reader.readAsText(file);
            })
        }

}


