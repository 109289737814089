import ElectronHelper from "./ElectronHelper";

const {electronAPI} = window;

export default class FilePath {

    //https://stackoverflow.com/questions/37213696/how-can-i-get-the-path-that-the-application-is-running-with-typescript
    static async getPath(fullPath) {

        // TODO get file://D:/documents/dev/pico/libs/js/my-electron-app
        if (ElectronHelper.isElectron()) {
            const appPath = await electronAPI.getAppPath();
            const result = `file://${appPath}/build${fullPath}`
            return result;

        }

        return fullPath;
    }

}