
// const seedrandom = require('seedrandom');


// this.rng()

// TODO figure out how to compile this for browser and node.
// ajv might be a good example.
const seedrandom = (seed) => () => Math.random();

/**
 * Kings are 0, 12, 24, 36.
 * 
 * @param cardVal1 
 * @param cardVal2 
 * @returns 
 */
const checkIsMatch = (cardVal1, cardVal2) => {
    
    // match king with self or another king.
    // if (cardVal1 === 0 && cardVal2 === 0) {
    //     return true;
    // }

    const sum = cardVal1 + cardVal2;

    if (sum % 13 === 0) {
        return true;
    }


    return false;
}


export class Pyramid {


    deck;
    rng; // rng function.
    shuffleSeed;
    pyramidArray = [];

    // should always be at least 2.
    drawnCards = [];

    randomNumber (min, max) {
        return Math.floor(this.rng() * (max - min) + min);
    }

    // create new array of cards
    getNewDeckArray() {
        let deck = [];
        for (let i = 0; i < 52; i++) {
            deck.push(i);
        }
        return deck;
    }

    constructor(shuffleSeed = Date.now()) {
        this.deck = this.getNewDeckArray();

        this.shuffleSeed = shuffleSeed;

        this.rng = seedrandom(shuffleSeed);

        // shuffle by default?
    }

    findFirstAvailableCardIndex(position) {
        for (let rowIndex = 0; rowIndex < 7; rowIndex++) {
            if (this.pyramidArray[rowIndex][position] !== -1) {
                return [rowIndex, position];
            }
        }
        return [-1,-1];
    }

    /**
     * 
     * @param cardPos1 - 0, 1 for drawn cards, 2 - 8
     * @param cardPos2 - 0, 1 for drawn cards, 2 - 8
     */
    doMatch(cardPos1, cardPos2) {
        let drawnCardsIndex1 = false;
        let drawnCardsIndex2 = false;

        let pyramidArrayIndex1 = false;
        let pyramidArrayIndex2 = false;

        let lastDrawnCardsIndex = this.drawnCards.length - 1;

        let cardVal1;
        let cardVal2;


        if (cardPos1 < 2) {
            drawnCardsIndex1 = (lastDrawnCardsIndex - 1) + cardPos1;
            cardVal1 = this.drawnCards[drawnCardsIndex1];
        } else {
            // find
            pyramidArrayIndex1 = this.findFirstAvailableCardIndex(cardPos1 - 2);
            cardVal1 = this.pyramidArray[pyramidArrayIndex1[0]][pyramidArrayIndex1[1]];
        }
        if (cardPos2 < 2) {
            drawnCardsIndex2 = (lastDrawnCardsIndex - 1) + cardPos2;
            cardVal2 = this.drawnCards[drawnCardsIndex2];
        } else {
            // find
            pyramidArrayIndex2 = this.findFirstAvailableCardIndex(cardPos2 - 2);
            cardVal2 = this.pyramidArray[pyramidArrayIndex2[0]][pyramidArrayIndex2[1]];

        }

        const isMatch = checkIsMatch(cardVal1,cardVal2);

        console.log(
            {
                drawnCards: this.drawnCards,
                drawnCardsIndex1,
                drawnCardsIndex2,
                pyramidArrayIndex1,
                pyramidArrayIndex2,
                cardPos1,
                cardPos2,
                cardVal1,
                cardVal2,
                cardValsMod: [cardVal1 % 13, cardVal2 % 13, (cardVal1 + cardVal2) % 13],
                isMatch
            }
        );

        // shift from drawn cards, or remove from pyramid
        if (isMatch) {
            let newDrawnCards = this.drawnCards.slice();
            if (drawnCardsIndex1 !== false) {
                newDrawnCards[drawnCardsIndex1] = null;
                // this.drawnCards.slice(drawnCardsIndex1, 1);
                // console.log(`do slice`, drawnCardsIndex1);
            }
            if (drawnCardsIndex2 !== false) {
                newDrawnCards[drawnCardsIndex2] = null;
            }

            if (pyramidArrayIndex1 !== false) {
                this.pyramidArray[pyramidArrayIndex1[0]][pyramidArrayIndex1[1]] = -1;
            }
            if (pyramidArrayIndex2 !== false) {
                this.pyramidArray[pyramidArrayIndex2[0]][pyramidArrayIndex2[1]] = -1;
            }

            this.drawnCards = newDrawnCards.filter((cardVal) => cardVal !== null);

            console.log(this.drawnCards,newDrawnCards);

        }



        // if drawn cards were used for a match and there are not enough drawn cards, draw new card.
        if (this.drawnCards.length < 2) {
            this.draw();
        }
        // process.exit(1);
    }

    /**
     * 
     */
    shuffle() {
        const newDeck = [];
        const deck = this.deck;
        for (let i = 0; i < 52; i++) {
            let index = this.randomNumber(0, 51 - i);
            const cardVal = deck[index];
            deck.splice(index,1);
            newDeck.push(cardVal);
        }
        this.deck = newDeck;
        // how to implement shuffle with rng.
        // should it pop at a specific index?
        // should it change the position of cards.
        // this.deck = this.deck.map((card) => {
        //     return (this.randomNumber(0,51));
        //         // return (card + 1) % 52;
        // });
    }

    initPyramidArray() {
        // 7 elements in each row for consistency.
        // shift 7, then 6 until 1. set null as empty
        // [
        //     [0,1,2,3,4,5,6]
        //     [0,1,2,3,4,5]
        //     [0,1,2,3,4]
        //     [0,1,2,3]
        //     [0,1,2]
        //     [0,1]
        //     [0]
        //     ]

        this.pyramidArray = [];

        for (let rowIndex = 0; rowIndex < 7; rowIndex++) {
            const row = [-1,-1,-1,-1,-1,-1,-1]
            for (let colIndex = 0; colIndex < 7; colIndex++) {
                if (colIndex + rowIndex < 7) {
                    const cardVal = this.deck.shift();
                    row[colIndex] = cardVal;
                }
            }
            this.pyramidArray.push(row);
        }



    }

    draw () {
        if (this.deck.length === 0) {
            this.deck = [...this.drawnCards];
            this.drawnCards = [];
        }
        const cardVal = this.deck.shift();
        this.drawnCards.push(cardVal);
        if (this.drawnCards.length < 2) {
            this.draw();
        }
    }

    /**
     * 
     */
    static startNewGame(shuffleSeed = Date.now()) {
        let obj = new this(shuffleSeed);

        obj.shuffle();

        obj.initPyramidArray();

        // always draw two cards to start.
        obj.draw();
        // obj.draw();


        return obj;
    }





}