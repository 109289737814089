

export default (state) => {
    const getLastOnDesk = desk => {
        const l = state.desk[desk].cards.length;
        if (l > 0) {
            return state.desk[desk].cards[l - 1];
        }
        return null;
    };

    const getAvailableDestinations = (index, first = false) => {
        const { type, number } = getCard(index);
        const destinations = [];
        if (number === 1) { // aces
            // finish pile
            for (let i = 0; i < 4; i++) {
                const { cards, el} = getPile('finish', i);
                if (cards.length === 0) {
                    destinations.push({
                        el: el,
                        target: {
                            dest: 'finish',
                            pile: i,
                            card: index
                        }
                    });
    
                    if (first) return destinations;
                }
            }
        }
        // other cards
        // move to finish pile
        const subCards = getSubCards(index);
        if (!subCards.length > 0) {
            for (let i = 0; i < 4; i++) {
                const l = state.finish[i].cards.length;
                if (l + 1 === number) {
                    const {
                        type: lastType
                    } = getLastOnPile('finish', i);
    
                    if (lastType === type) {
                        destinations.push({
                            el: state.finish[i].el,
                            target: {
                                dest: 'finish',
                                pile: i,
                                card: index
                            }
                        });
                        if (first) return destinations;
                        break;
                    }
                }
            }
        }
        // desk pile
        for (let i = 0; i < 7; i++) {
            const last = getLastOnDesk(i);
            if (last !== null) {
                if (canBePlacedOnCard(index, last)) {
                    destinations.push({
                        el: state.cards[last].el,
                        target: {
                            dest: 'desk',
                            pile: i,
                            card: index
                        }
                    });
                    if (first) return destinations;
                }
            } else { // empty desk, accepts only kings
                if (number === 13) { // kings
                    destinations.push({
                        el: state.desk[i].el,
                        target: {
                            dest: 'desk',
                            pile: i,
                            card: index
                        }
                    });
                    if (first) return destinations;
                }
            }
        }
        return destinations;
    };


    /**
 * Takes an array of cards for a solitaire game.
 * Avoid any html / UI specific code here.
 * 
 * appendToCard
 * appendChild
 * 
 * 
 * 
 * @param {*} index 
 * @returns 
 */

const getCard = index => state.cards[index];


/**
 * Remove element from an array.
 * splice like sort updates the array.
 * @param {*} array 
 * @param {*} element 
 */
const remove = (array, element) => {
    const index = array.indexOf(element);

    if (index !== -1) {
        array.splice(index, 1);
    }
}

const placeCardTo = (dest, index, card) => {
    state[dest][index].cards.push(card);
    remove(state.deal.pile.cards, card);
};

const canBePlacedOnCard = (child, parent) => {
    const { type, number } = getCard(child);
    const {
        type: parentType,
        number: parentNumber
    } = getCard(parent);
    return (
        (parentNumber - 1) === number &&
        state.colors[parentType] !== state.colors[type]
    );
};



const getLastOnPile = (pile, index) => {
    const l = state[pile][index].cards.length;
    if (l > 0) {
        const card = state[pile][index].cards[l - 1];
        return state.cards[card];
    }
    return {};
};

const getCardLocation = card => {
    for (let i = 0; i < 7; i++) {
        const index = state.desk[i].cards.indexOf(card);
        if (index > -1) {
            return {
                location: 'desk',
                pile: i,
                index: index
            }
        }
    }

    for (let i = 0; i < 4; i++) {
        const index = state.finish[i].cards.indexOf(card);
        if (index > -1) {
            return {
                location: 'finish',
                pile: i,
                index: index
            }
        }
    }

    for (let i of ['deal', 'pile']) {
        const index = state.deal[i].cards.indexOf(card);
        if (index > -1) {
            return {
                location: 'deal',
                pile: i,
                index: index
            }
        }
    }
    // debugger;
    // 'Card not found!';
};

const getSubCards = card => {
    const { location, pile, index } = getCardLocation(card);

    return state[location][pile].cards.filter(
        (elem, i, array) => array.indexOf(elem) > index
    );
};

const getPile = (pile, index) => {
    return state[pile][index];
};

const moveCardTo = (dest, i, card) => {
    const {
        location,
        pile,
        index
    } = getCardLocation(card);

    const moving = state[location][pile].cards.filter(
        (elem, i, array) => array.indexOf(elem) >= index
    );

    // remove from source
    state[location][pile].cards = state[location][pile].cards.filter(
        (elem, i, array) => moving.indexOf(elem) === -1
    );

    // append to destination
    state[dest][i].cards = state[dest][i].cards.concat(moving);

    // console.log(state);
};

    return {
        getLastOnDesk,
        moveCardTo,
        getLastOnDesk,
        getCard,
        placeCardTo,
        getCardLocation,
        getLastOnPile,
        getAvailableDestinations
    }
}


