
import React from 'react';


// https://www.npmjs.com/package/midi-parser-js
// https://github.com/colxi/midi-parser-js/wiki/MIDI-File-Format-Specifications


// https://www.npmjs.com/package/react-midi-player
// https://reactjs.org/docs/web-components.html
// https://github.com/cifkao/html-midi-player

// https://developer.mozilla.org/en-US/docs/Web/API/Web_MIDI_API
// https://github.com/jazz-soft/react-midi-player

//  I guess what I was interested in was the midi parsing more than the playing itself.

// behavior is odd. Need to actually use source code to track down weirdness.


// midi player fails to update midi-visualizer.
const __html = `
<midi-visualizer type="piano-roll" id="visual"></midi-visualizer>
<midi-player
    src="https://magenta.github.io/magenta-js/music/demos/melody.mid"
    sound-font visualizer="#visual">
</midi-player>
`;

export default class MidiPlayer extends React.Component {


    createMarkup() {
        return {__html};
    }

    render() {
        return <div dangerouslySetInnerHTML={this.createMarkup()} />;
    }
}