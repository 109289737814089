// import logo from './logo.svg';
import './App.scss';
import Home from './components/Home';
import Demos from './components/Demos';
import Midi from './components/demos/Midi';
import Synth from './components/synth/Synth';

import {
    BrowserRouter as Router,
    // Switch,
    Route,
    Routes
} from "react-router-dom";
import MidiParser from './components/demos/MidiParser';
import SoundfontPlayerDemo from './components/demos/SoundfontPlayer/SoundfontPlayerDemo';
import PlayWav from './components/demos/wav/PlayWav';
import Famitracker from './components/famitracker/Famitracker';
import Tetris from './components/tetris/Tetris';

import MarkdownEditor from './components/markdown/MarkdownEditor';
import ChessApp from './chess/ChessApp';
import SolitaireApp from './solitaire/SolitaireApp';
import CheckersApp from './checkers/CheckersApp'
import { ViewMyGames } from './games/ViewMyGames';
import PyramidComponent from './games/pyramid/PyramidComponent';

const Piano = window.Piano;


// WARNING in ./node_modules/nth-check/lib/esm/parse.js
// Module Warning (from ./node_modules/source-map-loader/dist/cjs.js):
// Failed to parse source map from 'D:\documents\dev\pico2\libs\js\react-app-src\node_modules\nth-check\lib\esm\https:\raw.githubusercontent.com\fb55\nth-check\639fd2a4000b69f82350aad8c34cb43f77e483ba\src\parse.ts' file: Error: ENOENT: no such file or directory, open 'D:\documents\dev\pico2\libs\js\react-app-src\node_modules\nth-check\lib\esm\https:\raw.githubusercontent.com\fb55\nth-check\639fd2a4000b69f82350aad8c34cb43f77e483ba\src\parse.ts'


function App() {

  console.log(process.env);

  if (process.env.REACT_APP_TYPE === 'electron') {
    return (<div>
Electron
<SoundfontPlayerDemo></SoundfontPlayerDemo>
    </div>);
  }

  return (

        <Router>
            {/*https://stackoverflow.com/questions/63124161/attempted-import-error-switch-is-not-exported-from-react-router-dom*/}
            <Routes>
                <Route exact path="/" element={<Home />} />
      
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/demos" element={<Demos />} />
                <Route exact path="/demos/midi" element={<Midi />} />
                <Route exact path="/demos/midiparse" element={<MidiParser />} />
                <Route exact path="/demos/soundfont" element={<SoundfontPlayerDemo />} />
                <Route exact path="/synth" element={<Synth />} />
                <Route exact path="/demos/wav" element={<PlayWav />} />
                <Route exact path="/famitracker" element={<Famitracker />} />
                <Route exact path="/tetris" element={<Tetris />} />
                <Route exact path="/md-editor" element={<MarkdownEditor />} />

                <Route exact path="/chess/" element={<ChessApp />} />
                <Route exact path="/chess/:id" element={<ChessApp />} />
                <Route exact path="/solitaire" element={<SolitaireApp />} />

                <Route exact path="/checkers/" element={<CheckersApp />} />
                <Route exact path="/checkers/:id" element={<CheckersApp />} />
                <Route exact path="/mygames" element={<ViewMyGames />} />

                <Route exact path="/pyramid" element={<PyramidComponent />} />


            </Routes>

{/* footer needs to be added to each component */}
            {/* <div>
            <span>app visualizer</span>
            <midi-visualizer type="piano-roll" id="visual"></midi-visualizer> 

                    footer
                    <NavBar/>
                    </div> */}
        </Router>

        

  );
}

export default App;
