import React from 'react';

import {
  BrowserRouter as Router,
  // Switch,
  Route,
  Routes,
  Navigate,
  Link,
  useParams,
  redirect
} from "react-router-dom";


// https://github.com/jhlywa/chess.js
import { Chess } from 'chess.js'

import axios from 'axios';
import GameLogin from '../games/GameLogin';
import PickASide from '../games/PickASide';
import { sleep } from '../libs/utils/utils';

import {handleChessBoardMain as handleChessBoardMainFunc} from './handleChessBoardMain';


const chessApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_GAMEAPI}/v2/chess`
});

// https://peerjs.com/docs/#api


let game;
let board;

let gameId;
let pingGameState = window.chessPingGameState;




const Chessboard = window.Chessboard;

let pathname;

export default class ChessAppMain extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.state.gameId = props.gameId || null;

    this.state.enableDebug = false;

    if (props.location) {
      pathname = props.location.pathname;

      if (props.location.search) {
        if (props.location.search.indexOf('debug') !== -1) {
          this.state.enableDebug = true;
        }
      }

    }

    let playerName = '';
    playerName = window.localStorage.getItem(`game-playerName`);
    // get config based on playerName
    this.state.config = {};

    this.state.playerName = playerName;

    this.state.turn = '';
    this.state.fen = '';
  }

  //https://chessboardjs.com/examples.html#2005

  /**
   * Show previous move by checking the history and updating the game
   * board state and then back.
   * 
   * TODO better highlighting of squares.
   */
  async showLastMove() {
    try {
      // disable checkGameState
      clearInterval(pingGameState);

      this.state.fen = game.fen();
      const { data: history } = await chessApiClient({
        method: 'GET',
        url: `${this.state.gameId}/history`
      });
      console.log({ history });

      console.log(`set board position`, history[1]);
      board.position(history[1].fen);
      await sleep(1000);

      board.position(history[0].fen);

      await sleep(1000);

      // renable checkGameState
      clearInterval(pingGameState);
      window.chessPingGameState = pingGameState = setInterval(() => {
        this.checkGameState();
      }, 1000);


    } catch (err) {
      console.error(err);
    }

  }

  /**
   * Get the game state if it is not your turn.
   * 
   * Called periodically with setInterval. This would be
   * better handled with a permenant websocket to the API.
   * 
   * TODO websocket.
   */
  async checkGameState() {
    const result = await chessApiClient.get(this.state.gameId);

    const { data: { fen } } = result;

    if (fen && fen !== game.fen()) {

      console.log(`game fen updated`, fen, game.fen());
      game.load(fen);
      board.position(game.fen());

      this.setState({ turn: game.turn() });
    }
  }

  /**
   * Called whenever the component state updates and on first component mount.
   * Only update if the game hasn't been initialized.
   * 
   */
  async loadGameOnUpdate() {
    if (game) {
      return;
    }
    if (!this.state.config.orientation) {
      return;
    }
    if (!this.state.gameId) {
      return;
    }
    // https://chessboardjs.com/examples.html#5000
    game = new Chess();
    gameId = this.state.gameId;


    // await this.doLoad();

    const result = await chessApiClient.get(this.state.gameId);
    console.log(`got game result`, { result });

    const { data: { fen } } = result;
    // const fen = '';
    console.log(`componentDidMount`, this.state.gameId, game);

    let chessJsConfig = {
      orientation: this.state.config.orientation === 'b' ? 'black' : 'white'
    }

    this.handleChessBoardMain(this.updateBoard.bind(this), fen, chessJsConfig);

    // Only create setInterval if not already there.
    if (!window.chessPingGameState) {
      window.chessPingGameState = pingGameState = setInterval(() => {
        this.checkGameState();
      }, 1000);
    }
  }

  /**
   * Called whenever the page is loaded or refreshed once only.
   * @param {*} prevProps 
   * @param {*} prevState 
   * @returns 
   */
  async componentDidMount(prevProps, prevState) {
    return this.loadGameOnUpdate();

  }

  /**
   * Called whenever the state gets an update. This includes 
   * whenever a move is detected.
   * @param {*} prevProps 
   * @param {*} prevState 
   * @returns 
   */
  async componentDidUpdate(prevProps, prevState) {
    return this.loadGameOnUpdate();
  }


  async updateBoard(fen) {
    console.log('updateBoard', fen, this, this.state);

    await chessApiClient({
      method: 'POST',
      url: this.state.gameId,
      data: {
        fen
      }
    })
  }
  /**
   * electron peer 2 peer.
   * 
   * game controls the actual chess game logic.
   * board controls the display.
   * 
   * https://github.com/jhlywa/chess.js/blob/master/README.md
   * 
   * TODO add history
   * 
   */
  handleChessBoardMain(updateBoard, fen = '', config) {

    ({board} = handleChessBoardMainFunc(this, game, updateBoard, fen, config, this.state.enableDebug));
  }


  async newBoard(el) {
    console.log(`newBoard`, el);

    // create new chess game
    const result = await chessApiClient({
      method: 'POST'
    });
    console.log(result.status, result.data);

    const id = result.data.id;

    // redirect(`/${id}`);
    window.location = (`${pathname}/${id}`);

    // redirect to this game.


    // await client
  }

  setOrientation(orientation) {
    console.log(this, this.state, orientation);

    const config = {
      orientation
    };

    window.localStorage.setItem(`chess-${this.state.gameId}`, JSON.stringify(config));


    this.setState({ ...this.state, config })
  }

  render() {

    let youAreInCheck = (<></>);

    if (!this.state.playerName) {
      // display login.
      return <GameLogin
        playerName={this.state.playerName}
        playerCallback={(player) => {
          console.log(`player name set to `, { player });
          this.setState({ playerName: player.playerName });
          window.localStorage.setItem(`game-playerName`, player.playerName);
        }}
      ></GameLogin>
    } else {
      // wait for player to load. loader will set playerName to '' if not found.
    }
    // this.state.playerName
    // }
    if (!this.state.gameId) {
      return (
        <button onClick={(el) => {
          console.log(this, el, this.newBoard(el));
        }}>New game</button>
      )

    }

    // add and not loading config or have PickASide handle loading.
    if (!this.state.config.orientation) {

      return (
        <PickASide gameType={"chess"}
          gameId={this.state.gameId}
          playerName={this.state.playerName}
          setConfigCallback={(config) => {
            console.log(`config set to `, config);
            this.setState({
              ...this.state,
              config,
            });
          }}
        ></PickASide>
      )
    }

    let boardDiv = '';
    if (this.state.gameId) {
      if (this.state.config.orientation) {
        if (this.state.inCheck) {
          youAreInCheck = (<div>You are in check</div>);
        }
        let currentTurn = (<span>Current Turn: {this.state.turn === 'b' ? 'Black' : 'White'}</span>);
        if (this.state.winner) {
          currentTurn = (
            <div>
              <div>
                Game Over
              </div>
              <div>
                <span>Winner: {this.state.winner === 'b' ? 'Black' : 'White'}</span>
              </div>

            </div>
          );
        }
        boardDiv = (
          <div>
            <div>
              {youAreInCheck}
            </div>
            <div>
              You are Playing As {this.state.config.orientation === 'b' ? 'Black' : 'White'}
            </div>
            {currentTurn}
            <div id="board2" style={{ width: "400px" }}></div>

            <button onClick={() => this.showLastMove()}>Show Last Move</button>

          </div>
        );
      }

    } else {
      boardDiv = (
        <button onClick={(el) => {
          console.log(this, el, this.newBoard(el));
        }}>New game</button>
      )
    }
    return <div>
      {boardDiv}
    </div>;
  }
}
