import React from 'react';
import BaseComponent from '../../BaseComponent';


// https://zpl.fi/pitch-shifting-in-web-audio-api/

const instruments = [
    'meow.mp3', // https://freesound.org/people/Npeo/sounds/203121/
    'single_do_bark',  // https://freesound.org/people/crazymonke9/sounds/418105/
    'game_over',
    'whoa',
    'Alesis-Sanctuary-QCard-Music-Box-C6',
    'Roland-SC-88-Distorted-Guitar-C3',
    'Alesis-Sanctuary-QCard-Harpsilute-C5',
    'Alesis-Fusion-Clean-Guitar-C3', // https://freewavesamples.com/alesis-fusion-clean-guitar-c3
    'Korg-N1R-TubeCrunch-C4' //https://freewavesamples.com/korg-n1r-tubecrunch-c4
]

const instrumentOptions = instruments.map(el =>
    <option key={el} value={el}>{el || el}</option>
);

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

const gainNode = audioContext.createGain();
gainNode.connect(audioContext.destination);
gainNode.gain.value = .5;
// gainNode.connect(audioContext.destination)


const audioBuffers = {

};

export default class PlayWav extends BaseComponent {


    constructor(props) {
        super(props);

        this.state = {
            midiNotes: {},
            instrument: 'whoa',
            volume: 50
            // freq: null,
            // instrument: defaultInstrument
        }
    }

    handleKeyChange(midiNote,isPressed) {

        if (isPressed) {
            this.playWav(midiNote);
        } else {
        }


    }

    async componentDidUpdate(prevProps, prevState) 
    {
        Object.entries(this.state.midiNotes).forEach(([midi, isPressed]) => {
            if (prevState.midiNotes[midi] !== isPressed) {
                this.handleKeyChange(midi,isPressed);
            }
        });

        console.log(this.state.volume);
        if (this.state.volume !== prevState.volume) {
            gainNode.gain.value = Math.min((this.state.volume / 100),1);
        }
    }

    // https://github.com/facebook/react/issues/10135
    async componentDidMount(prevProps, prevState) {

        // move this to properties?
        const piano = new window.Piano();

        piano.on(
            'midi-note',
            ({midi}) => {
                // console.log(`midi played ${midi}`);
                // console.log('set state', this, this.setState);
                this.setState({
                    midiNotes: {
                        ...this.state.midiNotes,
                        [midi]: true
                    }
                })
            }
        );
        piano.on(
            'midi-note-up',
            ({midi}) => {
                // console.log('midi-note-up',{midi});
                this.setState({
                    midiNotes: {
                        ...this.state.midiNotes,
                        [midi]: false
                    }
                })
            }
        );
        window.addEventListener("keyup", (e) => {piano.handleKeyup(e)});

        window.addEventListener("keydown", (e) => {piano.handleKeydown(e)});
    }


    /**
     * TODO caching on the browser level may be enough.
     * @param {*} url 
     * @returns 
     */
    async getAudioBuffer(url) {
        if (!audioBuffers[url]) {
            const res = await fetch(url);
            const arrayBuffer = await res.arrayBuffer();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            audioBuffers[url] = audioBuffer;
            console.log({audioBuffer});
        }
        return audioBuffers[url];
    }

    getPlayBackRate(noteToPlay, sampleNote = 60) {
        return 2 ** ((noteToPlay - sampleNote) / 12);
    }

    // https://zpl.fi/pitch-shifting-in-web-audio-api/
    async playWav(midiNote = 60) {
        // console.log({midiNote});
        if (!midiNote || midiNote === 'undefined') {
            return;
        }
        let url;
        if (this.state.instrument.indexOf('.mp3') !== -1) {
            url = `/data/wav/${this.state.instrument}`

        } else {
            url = `/data/wav/${this.state.instrument}.wav`
        }
        // const url = '/data/wav/game_over.wav';

        const source = audioContext.createBufferSource();

        source.buffer = await this.getAudioBuffer(url);

        source.playbackRate.value = this.getPlayBackRate(midiNote);


        // source.connect(audioContext.destination);
        source.connect(gainNode);


        source.start();
    }

    // https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    render() {

        
        let instrumentSelect = (<div>Loading</div>);


        instrumentSelect = (
            <select name="instrument" id="instrument"
                value={this.state.instrument}
                onChange={(event) => this.handleChange(event)}
            >
                {instrumentOptions}

            </select>
        );

        return (
            <div>
                <h1>
                    Wav Player
                </h1>

                <input type="number" min="0" max="100" step="1"  name="volume" id="volume"  value={this.state.volume} onChange={(event) => this.handleChange(event)}
                />
                {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange"> */}


                {instrumentSelect}

                <button onClick={() => this.playWav()}>Play</button>

            </div>
        )
    }
}