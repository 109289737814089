import React from 'react';
import MidiSummaryTable from './summary/MidiSummaryTable';
import TrackSummaryTable from './summary/TrackSummaryTable';


const trackHeaders = [
    'track_num',
    'track_start',
    'track_end',
    'track_duration',
    'note_count',
    'inst_name',
    'inst_num',
];


export default class MidiSummary extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            midi: props.midi
        }
    }

    /**
     * 
     * @param {*} track 
     */
    getTrackRowArray(track) {
        const {notes,pitchBends,instrument,controlChanges} = track;
        const {number,family, name, percussion} = instrument;

    }

    
    // async componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.midi !== this.props.midi) {
    //         const midi = this.props.midi;
    //         this.setState({ midi });
    //     }
    // }

    //https://github.com/Tonejs/Midi
    render() {

        const {midi} = this.state;
        return (

            <div>

            {midi ? (
            <>
            <MidiSummaryTable midi={midi}></MidiSummaryTable>
            <TrackSummaryTable midi={midi}></TrackSummaryTable>
            </>
            )
        : <div>No midi file selected</div>    
        }



            {/* {track &&  <MidiNotestViewer midi={midi} track={track}></MidiNotestViewer>} */}
           
{/* TODO option to expand collapse */}
                {/* {notesEl} */}

                {/*<pre>{JSON.stringify(notes,null,2)}</pre>*/}
            </div>
        )
    }
}