//   https://kingrayhan.medium.com/how-do-i-use-jquery-in-react-app-976c124f5448
  import $ from 'jquery';
  
  let board;
  let game;

  var whiteSquareGrey = '#a9a9a9'
  var blackSquareGrey = '#696969';

  const boardId = `board2`;

  function onMouseoverSquare (square, piece) {
    console.log(`onMouseoverSquare`,square);
    // get list of possible moves for this square
    var moves = game.moves({
      square: square,
      verbose: true
    })
    console.log(`onMouseoverSquare`,moves);

    // exit if there are no moves available for this square
    if (moves.length === 0) return
  
    // highlight the square they moused over
    greySquare(square)
  
    // highlight the possible squares for this piece
    for (var i = 0; i < moves.length; i++) {
      greySquare(moves[i].to)
    }
  }


  function onMouseoutSquare (square, piece) {
    removeGreySquares()
  }

  function removeGreySquares () {
    $('#board2 .square-55d63').css('background', '')
  }
  
  function greySquare (square) {
    var $square = $('#board2 .square-' + square);

    console.log(`greySquare`);
  
    var background = whiteSquareGrey
    if ($square.hasClass('black-3c85d')) {
      background = blackSquareGrey
    }
  
    $square.css('background', background)
  }

  /**
   * electron peer 2 peer.
   * 
   * game controls the actual chess game logic.
   * board controls the display.
   * 
   * https://github.com/jhlywa/chess.js/blob/master/README.md
   * 
   * TODO add history
   * 
   * flash effect fixed by reenabling cache.
   * 
   * It was attempting to reload images after every move.
   * 
   */
  export const handleChessBoardMain = (component, currentGame, updateBoard, fen = '', config, debug = false) => {

    console.log(`handleChessBoardMain`);

    game = currentGame;
    const Chessboard = window.Chessboard;


    if (fen) {
      //https://github.com/jhlywa/chess.js/blob/master/README.md
      game.load(fen);
    }
    component.setState({ turn: game.turn(), inCheck: game.inCheck() });


    const checkGameOver = () => {
        console.log(`checkGameOver`);
        if (game.isGameOver()) {
            console.log({ game });
            if (game.isCheckmate()) {
              component.setState({ winner: game.turn() === 'w' ? 'b' : 'w' });
            } else {
              // no winner
              component.setState({ winner: 'draw' });
            }
          }
    }

    checkGameOver();

    let fromSource = null;
    let toSource = null;


    /**
     * Keep track of first click then second click.
     * 
     * All I really need to keep track of is source 1 and
     * source 2.
     * @param {*} source 
     * @param {*} piece 
     * @param {*} position 
     * @param {*} orientation 
     */
    function onClick(source, piece, position, orientation) {

        // If fromSource is not selected and is a valid piece for the current player
        // turn set as fromSource.
        if (!fromSource && piece) {
            fromSource = source;
            console.log({
                source,
                piece,
                position,
                orientation
            });
                  // do not check if your turn for debug.
            if (debug) {
                return true;
            }
            // TODO gray legal moves.
        } else {
            // castling involves clicking on empty squares still I think?
            // There could be an extra check here that the square is either empty of of the opposing side.
            toSource = source;
            // do move.
            handleMove();
        }

    }

    function handleMove() {

        let from = fromSource;
        let to = toSource;

        fromSource = null;
        toSource = null;

        if (game.isGameOver()) {
            return false;
        }



              // see if the move is legal
        const move = game.move({
            from,
            to,
            promotion: 'q' // NOTE: always promote to a queen for example simplicity
        });
        console.log({from,to,move});

        // illegal move
        if (move === null) return 'snapback'

        updateStatus();

    }


    function updateStatus() {
        const fen = game.fen();
        board.position(fen);
        updateBoard(fen);
  
        component.setState({ turn: game.turn() });

        console.log(`updateStatus`);
       checkGameOver();

      var status = ''

      var moveColor = 'White'
      if (game.turn() === 'b') {
        moveColor = 'Black'
      }

      // checkmate?
      if (game.isCheckmate()) {
        status = 'Game over, ' + moveColor + ' is in checkmate.'
      }

      // draw?
      else if (game.isDraw()) {
        status = 'Game over, drawn position'
      }

      // game still on
      else {
        status = moveColor + ' to move'

        // check?
        if (game.inCheck()) {
          status += ', ' + moveColor + ' is in check'
        }
      }

      // $status.html(status)
      // $fen.html(game.fen())
      // $pgn.html(game.pgn())
    }

    //https://chessboardjs.com/examples.html#5003
    const boardConfig = {
      ...config,
      // orientation: 'black',
      pieceTheme: '/libs/chessboardjs-1.0.0/img/chesspieces/wikipedia/{piece}.png',


    //   draggable: true,

      position: 'start',
      onClick,
    //   onDragStart,

      onMouseoverSquare,
      onMouseoutSquare,

      // TODO handle as click instead of drag to make more mobile friendly.
      // TODO show moves on click
      // https://github.com/jhlywa/chess.js/pull/29/files
    //   onDrop,
    //   onSnapEnd,
      moveSpeed: 'slow',
      snapbackSpeed: 500,
      snapSpeed: 100,
    };

    board = Chessboard('board2', boardConfig)

    board.position(game.fen());

    window.board = board;


    return {
        board,
        game
    }
  }