import React from 'react';

import axios from 'axios';
import GameLogin from './GameLogin';


const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_GAMEAPI}/v2/players`
  });

class ViewMyGames extends React.Component {

    //https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
    constructor(props) {
        super(props);

        this.state = {};

        let playerName = '';
        playerName = window.localStorage.getItem(`game-playerName`);
    
        this.state.playerName = playerName;
    

    }


    // get player details.
    async componentDidMount(prevProps, prevState) {


        try {
            let configs = await this.loadConfigs();

            console.log('loaded config', configs);

        } catch (err) {

        }
        // this.setState({
        //     ...this.state,
        //     loadingConfig: false,
        // })

    }

    async componentDidUpdate(prevProps, prevState) {
    }

    //   handleChange(event) {
    //     this.setState({value: event.target.value});
    //   }

    handleChange(event) {
        let input = this.state.input;
        let val;
        if (event.target.type === 'checkbox') {
            val = event.target.checked;
        } else {
            val = event.target.value;
        }
        input[event.target.name] = val;
        this.setState({ input });
    }

    async createNewUserOrLogin() {
        const {
            playerName
        } = this.state.input;

        console.log('create new user as', playerName);

        try { 
            const result = await apiClient({
                method: 'POST',
                url: '/',
                data: {
                    playerName
                }
            });

            console.log(result.status);
            return result.data;
        } catch (err) {
            console.log(err);
        }
    }

    async loadConfigs() {
        const {
            playerName
        } = this.state;

        const url = `/getByName/${playerName}/configs`;

        try {
            const result = await apiClient({
                method: 'GET',
                url
            });

            this.setState({
                ...this.state,
                configs: result.data,
            })
            // this.state.configs = result.data;

            return result.data;
        } catch (err) {
            // console.log(`loaded config`, result.status,result.data);

        }


        return {};


    }

    async handleSubmit(event, isNewUser = false) {
        console.log('An input was submitted: ', this.state.input);
        event.preventDefault();

        let player;
        // id and 
        if (isNewUser) {
            player = await this.createNewUserOrLogin();
        } else {
            player = await this.login();
        }
        const {playerName,id} = player;

        if (id) {
            // call callback in state
            this.state.playerCallback({playerName,id});
        }
    }

    render() {

      if (!this.state.playerName) {
        // display login.
        return <GameLogin
        playerName={this.state.playerName}
        playerCallback={(player) => {
          console.log(`player name set to `, {player});
          this.setState({playerName: player.playerName});
          window.localStorage.setItem(`game-playerName`, player.playerName);
        }}
        ></GameLogin>
      }

      if (!this.state.configs) {
        return (
            <div>
                Loading config.
            </div>
        )
      }

        return (

            <div className={`game-view`}>
                <div>
                    Viewing games for {this.state.playerName}
                </div>

                <div>
                {this.state.configs.map((config, i) => {

                    return (<div key={`config-${i}`}>
                        <a href={`${config.gameType}/${config.gameId}`}>{config.gameType} {config.gameId}</a>
                        {/* {i} */}
                    </div>)
                })}
                </div>
     
            </div>

        )
    }
}


export {
    ViewMyGames
}