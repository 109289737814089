


// const defaultInstrumentUrl = '/data/wav/Alesis-Sanctuary-QCard-Music-Box-C6.wav';

import FilePath from "..//FilePath";

// TODO percussion.
// const defaultInstrumentUrl = '/data/wav/Alesis-Fusion-Clean-Guitar-C3.wav';
const defaultInstrumentUrl = '/data/wav/Ensoniq-SQ-2-Piano-C4.wav';

const audioBuffers = {};

const instruments = {
    0: '/data/wav/Ensoniq-SQ-2-Piano-C4.wav',
    30: '/data/wav/Roland-SC-88-Distorted-Guitar-C3.wav', // distortion guitar
    29: '/data/wav/Korg-N1R-TubeCrunch-C4.wav', // overdriven guitar
    48: '/data/wav/Alesis-Fusion-Clean-Guitar-C3.wav', // string ensemble TODO pick better sample
};

const MIDI_C3 = 48;
const MIDI_C4 = 60;


const instrumentSampleNote = {
    '/data/wav/Ensoniq-SQ-2-Piano-C4.wav': MIDI_C4,
    '/data/wav/Alesis-Fusion-Clean-Guitar-C3.wav': MIDI_C3, // string ensemble TODO pick better sample
    '/data/wav/Roland-SC-88-Distorted-Guitar-C3.wav': MIDI_C3,
    '/data/wav/Korg-N1R-TubeCrunch-C4.wav': MIDI_C4,
}

export default class Instrument {



    constructor(audioContext, instrumentNum, mainNode) {
        this.audioContext = audioContext;
        this.instrumentNum = instrumentNum;
        this.eventListeners = {};

        this.sampleNote = MIDI_C4; //60
        console.log({mainNode})
        this.destination = mainNode || this.audioContext.destination;

    }

    async getInstrumentUrl() {
        return FilePath.getPath( instruments[this.instrumentNum] || defaultInstrumentUrl);
    };

        /**
     * TODO caching on the browser level may be enough.
     * @param {*} url 
     * @returns 
     */
   async load() {
        // const url = defaultInstrumentUrl;
        const url = await this.getInstrumentUrl();
        this.sampleNote = instrumentSampleNote[url] || 60;
        const res = await fetch(url);
        const arrayBuffer = await res.arrayBuffer();
        console.log({res,arrayBuffer});
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        console.log({audioBuffer});
        this.audioBuffer = audioBuffer;

        // const source = this.audioContext.createBufferSource();
        // source.connect(this.audioContext.destination);
        // this.source = source;
    }

    on(name, callback) {
        this.eventListeners[name] =  this.eventListeners[name] || [];

        this.eventListeners[name].push(callback);

    }

    emit(name, data) {

        if (this.eventListeners[name]) {
            this.eventListeners[name].forEach(func => func(data));
        }
    }


    getPlayBackRate(noteToPlay, sampleNote = this.sampleNote) {
        return 2 ** ((noteToPlay - sampleNote) / 12);
    }


    start(midiNote, time, {duration}) {

        // const {source} = this;
        const playbackRate = this.getPlayBackRate(midiNote);

        
        const source = this.audioContext.createBufferSource();

        // source.connect(this.audioContext.destination);
        source.connect(this.destination);

        source.playbackRate.value = playbackRate;
        source.buffer = this.audioBuffer;

        // source.onstatechange = () => {
        //     console.log(source.state);
        //   };

        //   source.addEventListener = () => {
        //     console.log(source.state);
        //   };
        source.addEventListener('ended', (e) => {
            console.log(`ended`,source, {e});
            this.emit('ended');
        });

        source.start(time, 0, duration);

        console.log(`start`,{midiNote, duration});




        
    } 


    static async loadInstrument(audioContext, instrumentNum, mainNode) {
        console.log(`loadInstrument`, instrumentNum);
        // const audioBuffer = await this.getAudioBuffer(defaultInstrumentUrl, audioContext);


        let instrument = new Instrument(audioContext, instrumentNum, mainNode);

        await instrument.load();

        return instrument
    }
}