import React from 'react';
import MagentaPlayer from "../magenta/MagentaPlayer";
import MidiHeaderInfo from './MidiHeaderInfo';
import MidiNotestViewer from './MidiNotesViewer';

export default class MidiTrack extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            midi: props.midi,
            track: props.track,
            song: null,
            bpmModifier: 1,
            useMagentaPlayer: props.useMagentaPlayer === false ? false : true
        }

        if (this.state.midi) {
            const song = this.getMagentSongFromMidiTrack(this.state.track);

            this.state.song = song;
        }

    }


    async componentDidUpdate( prevProps,  prevState) {
        console.log(`MidiTrack`,'componentDidUpdate');
        if (this.props.track !== prevProps.track) {
            console.log(`MidiTrack has new state`);
            const track = this.props.track;

            this.setState({
                ...this.state,
                midi: this.props.midi,
                track: track,
                // loaded: false,
            });
            // await this.loadTrack();

        }
    }

    
    // bpm
    // https://blog.storyblocks.com/tutorials/what-are-beats-per-minute-bpm/#:~:text=So%20when%20you%20count%20how,Voila!

    // Largo (very slow) is 40–60 BPM.
    // Larghetto (less slow) is 60–66 BPM.
    // Adagio (moderately slow) is 66–76 BPM.
    // Andante (walking speed) is 76–108 BPM.
    // Moderato (moderate) is 108–120 BPM.
    // Allegro (fast) is 120–168 BPM.
    // Presto (faster) is 168–200 BPM.
    // Prestissimo (even faster) is 200+ BPM.

    // https://www.quora.com/How-many-beats-are-in-a-quarter-note-and-how-long-is-a-quarter-note
    // 4 / 4 time a quarter note is one beat.
    //https://stackoverflow.com/questions/2038313/converting-midi-ticks-to-actual-playback-seconds
    getMsPerTick ({bpm,ppq}) {
        return (60000 / (bpm * ppq)) * this.state.bpmModifier;
    }

    getMagentSongFromMidiTrack(track) {
        const {notes,pitchBends,instrument,controlChanges} = track;
        const {number,family, name, percussion} = instrument;
        const {header} = this.state.midi;
        const {ppq, tempos, timeSignatures} = header;
        const tempo1 = tempos[0];
        const {bpm,ticks} = tempo1;

        const msPerTick = this.getMsPerTick({bpm,ppq});
        const secondsPerTick = msPerTick / 1000;

        let startTime = 0;
        // TODO tempo...
        // let divideBy = 1000;
        // divideBy = bpm;

        // let lastNote;

        const magentaSongNotes = notes.map(note => {
            const {duration, durationTicks, midi, name, ticks, time, velocity} = note;
            const noteStartTime = startTime;

            const realTimeStart = (ticks * secondsPerTick );
            const realTimeDuration = (durationTicks * secondsPerTick);
            // startTime += realTimeDuration;

            return {
                // program: instrument,
                pitch: midi,
                startTime: realTimeStart, // noteStartTime,
                endTime: (realTimeStart + realTimeDuration),
                meta: {
                    ticks,
                    msPerTick,
                    secondsPerTick
                }
            };
        });

        if (magentaSongNotes && magentaSongNotes.length > 0) {
            const totalTime = magentaSongNotes[magentaSongNotes.length - 1].endTime;

            const song = {
                notes: magentaSongNotes,
                totalTime,
            };
            return song;

        } else {
            return false;
        }



    }



    //https://github.com/Tonejs/Midi
    render() {
        // TODO use midi data for tempo settings
        const {track, useMagentaPlayer, midi} = this.state;
        // parse display midi file.
        const {notes,pitchBends,instrument,controlChanges} = track;
        const {number,family, name, percussion} = instrument;

        const magentaSong = this.getMagentSongFromMidiTrack(track);

        let notesEl = (<div>
                {notes.map((note, idx) => {
                    const {duration, durationTicks, midi, name, ticks, time, velocity} = note;
                    const magentaNote = this.state.song.notes[idx];
                    return (<div key={idx}>
                        <span>{name} - {ticks} - {durationTicks} - {Math.floor(velocity * 100)}</span>
                        <span>{JSON.stringify(magentaNote)}</span>
                    </div>);
                })}
        </div>);            
        // console.log(`midi track`,{midi});

        // include magenta player.
        // const magentaSong = this.state.song;
        return (
            <div>
                <MidiHeaderInfo header={midi.header}></MidiHeaderInfo>

                <h2>Instrument</h2>
                <table>
                    <thead>
                    <th>Track #</th>
                    <th>Inst. #</th>
                    <th>Inst. Name</th>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{number}</td>
                            <td>{family}</td>
                        </tr>
                    </tbody>
                </table>

                <div>{number} - {family} - {name}</div>
                <span>Note - Ticks (start) - durationTicks - Velocity</span>
                {/* <pre>{JSON.stringify(midi.header)}</pre> */}
                {useMagentaPlayer && <MagentaPlayer song={magentaSong} instrumentNumber={number}></MagentaPlayer>}

            {track &&  <MidiNotestViewer midi={midi} track={track}></MidiNotestViewer>}
           
{/* TODO option to expand collapse */}
                {/* {notesEl} */}

                {/*<pre>{JSON.stringify(notes,null,2)}</pre>*/}
            </div>
        )
    }
}