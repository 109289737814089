import React from 'react';
import {
    NavLink
} from "react-router-dom";
import NavBar from './NavBar';

export default class Home extends React.Component {


    constructor(props) {
        super(props);

    }


    render() {

        const vars = process.env;

        return (<div>

            <div>
                <div>
                    <midi-visualizer type="piano-roll" id="visual"></midi-visualizer>

                    footer
                    <NavBar />
                </div>
            </div>
        </div>);
    }
}