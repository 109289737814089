import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import ViewMarkdown from './ViewMarkdown';


export default class MarkdownEditor extends React.Component {


    
    constructor(props) {
        super(props);

        this.state = {
            value: ''
        }

    }

    setValue(event) {
        // console.log(`setValue`,event);
        this.setState({
            value: event
        })
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
      }

    // https://github.com/brikis98/wmd
    // https://github.com/caolan/wmd
    // https://stackoverflow.com/questions/2874646/which-stack-overflow-style-markdown-wmd-javascript-editor-should-we-use
    // https://github.com/openlibrary/wmd

    //https://en.wikipedia.org/wiki/Markdown

    // Markdown extra

    // https://commonmark.org/

    // https://spec.commonmark.org/0.29/#html-blocks

    // https://www.mkdocs.org/getting-started/#building-the-site

    // https://github.com/joakin/markdown-folder-to-html

    // https://stackoverflow.com/questions/29044518/safe-alternative-to-dangerouslysetinnerhtml
    render() {

        const {value} = this.state;
        console.log({value});

        // wmd()
        // const [value, setValue] = React.useState("**Hello world!!!**");
        return (
          <div className="container">
            <MDEditor
              value={value}
              onChange={(e) => this.setValue(e)}
            />
            <ViewMarkdown 
            value={value}
            ></ViewMarkdown>
            {/* <form onSubmit={this.handleSubmit}> */}
                <MDEditor.Markdown 
                source={value} 
                style={{ whiteSpace: 'pre-wrap' }} />
            {/* </form> */}

          </div>
        );
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
}