import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import {makeHtml} from 'wmd';


// https://www.markdownguide.org/tools/jekyll/
export default class ViewMarkdown extends React.Component {


    
    constructor(props) {
        super(props);
        console.log({props});

        this.state = {
            value: props.value
        }

    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    setValue(event) {
        console.log(`setValue`,event);
        this.setState({
            value: event
        })
    }

    // markdown convert to react form and use this?

    // parse markdown within react and use that?
    // parse markdown on startup?
    handleSubmit(event) {
        // alert('A name was submitted: ' + this.state.value);
        // event.preventDefault();
      }

    // https://github.com/brikis98/wmd
    // https://github.com/caolan/wmd
    // https://stackoverflow.com/questions/2874646/which-stack-overflow-style-markdown-wmd-javascript-editor-should-we-use
    // https://github.com/openlibrary/wmd

    //https://en.wikipedia.org/wiki/Markdown

    // Markdown extra

    // https://commonmark.org/

    // https://spec.commonmark.org/0.29/#html-blocks
    render() {

        const {value} = this.state;
        let html;
        if (value) {
            html = makeHtml(value);
        }

        return (                <div dangerouslySetInnerHTML={{__html: html}} />
        )
        // const [value, setValue] = React.useState("**Hello world!!!**");
//         return (
//           <div className="container">
//             view markdown
//             <form onSubmit={(e) => {
//                 console.log(e)
//                 e.preventDefault();
//                 this.handleSubmit(e);
//                 return false;
//             }}>
//                 <div dangerouslySetInnerHTML={{__html: html}} />
//             </form>

// </div>
//         );
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
}