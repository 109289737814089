import { Midi } from '@tonejs/midi';

export default class MidiHelper {



    static async loadTrackFromFile(file) {
        const contentBuffer = await this.readFileAsync(file);
        const midi = new Midi(contentBuffer);


        return midi;
    }


    static async readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsArrayBuffer(file);
        })
    }

    // get additional details like start stop time.
    static getNoteDetails(note) {

    }


    // Largo (very slow) is 40–60 BPM.
    // Larghetto (less slow) is 60–66 BPM.
    // Adagio (moderately slow) is 66–76 BPM.
    // Andante (walking speed) is 76–108 BPM.
    // Moderato (moderate) is 108–120 BPM.
    // Allegro (fast) is 120–168 BPM.
    // Presto (faster) is 168–200 BPM.
    // Prestissimo (even faster) is 200+ BPM.
    /**
     * Get the tempo name based on BPM.
     * @param {*} bpm 
     * @returns 
     */
    static getTempoName(bpm) {
        if (bpm <= 60) {
            return 'largo';
        } else if (bpm <= 66) {
            return 'Larghetto';

        } else if (bpm <= 76) {
            return 'Adagio';

        } else if (bpm <= 108) {
            return 'Andante';

        } else if (bpm <= 120) {
            return 'Moderato';

        } else if (bpm <= 200) {
            return 'Presto';

        }

        return 'Prestissimo';
    }


    static getMsPerTick({ bpm, ppq }) {
        return (60000 / (bpm * ppq));
    }


    static getNotesFromMidiTrack(midi, track) {
        const { notes, pitchBends, instrument, controlChanges } = track;
        const { header } = midi;
        const { ppq, tempos } = header;
        const tempo1 = tempos[0];
        const { bpm } = tempo1;

        const msPerTick = this.getMsPerTick({ bpm, ppq });
        const secondsPerTick = msPerTick / 1000;

        const realNotes = notes.map(note => {
            const { durationTicks, midi, ticks } = note;

            const realTimeStart = (ticks * secondsPerTick);
            const realTimeDuration = (durationTicks * secondsPerTick);

            // TODO velocity adsr
            return {
                pitch: midi,
                startTime: realTimeStart, // noteStartTime,
                endTime: (realTimeStart + realTimeDuration),
            };
        });

        return realNotes;
    }

    /**
     * Returns info needed to play midi as a song using real world timings
     * in place of ticks.
     * 
     * Made to be compatible with magenta player but that may change in the future.
     * 
     * 
     * 
     * @param {*} midi 
     * @param {*} track 
     * @returns 
     */
    static getSongFromMidiTrack(midi, track) {
        const { notes, pitchBends, instrument, controlChanges } = track;
        if (!instrument) {
            return false;
        }
        const { number, family, name, percussion } = instrument;
        const { header } = midi;
        const { ppq, tempos, timeSignatures } = header;
        const tempo1 = tempos[0];
        const { bpm, ticks } = tempo1;

        const msPerTick = this.getMsPerTick({ bpm, ppq });
        const secondsPerTick = msPerTick / 1000;

        let startTime = 0;

        const realNotes = notes.map(note => {
            const { duration, durationTicks, midi, name, ticks, time, velocity } = note;

            const realTimeStart = (ticks * secondsPerTick);
            const realTimeDuration = (durationTicks * secondsPerTick);
            // startTime += realTimeDuration;

            return {
                // different sound fonts may define instruments in different ways but there is 
                // a common shared set of instrument numbers usually used.
                // instrumentNumber: number,
                program: number,
                // instrument: {
                // number, // midi number for instruement
                // family, name, percussion
                // },
                // program: instrument,

                // TODO velocity, decay attack etc. adsr envelop
                pitch: midi,
                startTime: realTimeStart, // noteStartTime,
                endTime: (realTimeStart + realTimeDuration),
            };
        });

        if (realNotes && realNotes.length > 0) {
            const totalTime = realNotes[realNotes.length - 1].endTime;

            const song = {
                notes: realNotes,
                totalTime,
            };
            return song;

        } else {
            return false;
        }



    }


    //https://github.com/danigb/midi-freq/blob/master/index.js
    static freq(m) {
        const tuning = 440;
        let result = m === 0 || (m > 0 && m < 128) ? Math.pow(2, (m - 69) / 12) * tuning : null;
        // console.log({result});
        return result;
        // if (arguments.length > 1) return this.freq(tuning)(midi)
      
        // return function (m) {
        //   return m === 0 || (m > 0 && m < 128) ? Math.pow(2, (m - 69) / 12) * tuning : null
        // }
    }
    /**
     * 
     * @param {*} tuning 
     * @param {*} midi 
     */
    // static freq(tuning,midi) {
    //     tuning = tuning || 440
    //     if (arguments.length > 1) return this.freq(tuning)(midi)
      
    //     return function (m) {
    //       return m === 0 || (m > 0 && m < 128) ? Math.pow(2, (m - 69) / 12) * tuning : null
    //     }
    // }
}