import React from 'react';

let instrumentOptions = [
];

// https://github.com/danigb/soundfont-player
const mm = window.mm;
const sgmSoundFontURL = 'https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus';

let player;
let viz;

export default class MagentaPlayer extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            playing: false,
            instrumentSelect: this.props.instrumentNumber || 0,
            instrumentOptions,
            song: props.song
        };

        player = new mm.SoundFontPlayer(sgmSoundFontURL,
            undefined, // Tone.Master,
            undefined, // programOutputs
            undefined, // drumOutputs
            {
                run: (note, t) => this.run(note, t),
                stop: () => this.stop()
            });

    }

    stop () {
        console.log(`stop`);
    }

    //https://magenta.github.io/magenta-js/music/classes/_core_player_.midiplayer.html
    run (note, t) {
        // endTime: 5
        // pitch: 65
        // program: 19
        // startTime: 4.5
        // console.log(note);

        console.log({t});

        viz.redraw(note);

    }


    async componentDidMount() {

        // console.log(`componentDidMount`);


        const initialize = async (soundFont) => {
            await soundFont.initialize();
            // console.log(soundFont);

            // set dropdown with full instrument list.
        }

        await initialize(player.soundFont);

        //
        // overdriven guitar

        // console.log(`add options`, pianoPlayer.soundFont);

        // Map element has no index loop.


        let newInstrumentOptions = [];

        player.soundFont.instruments.forEach((val, idx) => {
            // console.log({val,idx});

            const {baseURL} = val;
            if (baseURL) {
                const baseUrlSplit = baseURL.split('/');
                const urlName = baseUrlSplit[baseUrlSplit.length - 1];

                // console.log({val, urlName});

                newInstrumentOptions.push({
                    value: idx,
                    description: urlName
                })


            }

        });

        this.setState({
            ...this.state,
            instrumentOptions: newInstrumentOptions
        });

    }

    componentWillUnmount() {
    }


    playClick() {
        const song = this.state.song;
        let state = this.state;
        const instrument = + state.instrumentSelect;
        if (state.playing) {
            player.stop();
        }

        this.setState({
            ...this.state,
            playing: true,
        });

        song.notes.forEach(n => n.program = +instrument);


        viz = new mm.Visualizer(song, document.getElementById('canvas'));

        player.start(song);
    }

    stopClick() {
        player.stop();
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    componentDidUpdate(prevProps,prevState,snapshot) {
        console.log({prevProps,prevState,snapshot});

        if (prevProps.song !== this.props.song) {
            this.setState({
                song: this.props.song
            });
        }

        if (prevProps.instrumentNumber !== this.props.instrumentNumber) {
            this.setState({
                song: this.props.song,
                instrumentSelect: this.props.instrumentNumber
            });
        }

        // if (prevProps.text !== this.props.text) {
        //     this.updateAndNotify();
        // }
    }

    render() {
        const state = this.state;
        const {playing, song} = state;

        const options = this.state.instrumentOptions.map(el =>
            <option key={el.value} value={el.value}>{el.description || el.value}</option>
        );


        const instrumentSelect = (
            <select name="instrumentSelect" id="instrumentSelect"
                    value={this.state.instrumentSelect}
                    onChange={(event) => this.handleChange(event)}
            >
                {options}

            </select>
        );


        return (<div>
            Demos test

            <pre>
                    Selected Song
                {JSON.stringify(song)}
           </pre>


            {instrumentSelect}

            <button onClick={() => this.playClick()}>
                Play
            </button>

            <button onClick={() => this.stopClick()}>
                Stop
            </button>

            <div>{this.state.instrumentSelect}</div>

            <div>{`${playing}`}</div>


            <div>
                Display
                <h1>Display</h1>

                <div>
                    <canvas id="canvas"></canvas>
                </div>
            </div>


        </div>);
    }
}