import React from 'react';

// https://github.com/jhlywa/chess.js
// import initSolitaireMain from './index.js'
import './checkers.scss';
import {initCheckers} from './checkers.js'
import axios from 'axios';
import GameLogin from '../games/GameLogin';
import PickASide from '../games/PickASide';

let checkerGame;
let pathname;
let pingGameState;
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_GAMEAPI}/v2/checkers`
});
// https://github.com/rjanjic/js-solitaire

export default class SolitaireAppMain extends React.Component {

  //https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
  constructor(props) {
    super(props);

    this.state = {};

    // checkerGame = initCheckers(callback);

    this.state.board = null; // checkerGame.getBoardFull();

    this.state.fen = '';
    this.state.turn = '';
    this.state.clickedSquare = null;

    this.state.gameId = props.gameId || null;
    pathname = props.location.pathname;


    let playerName = '';
    playerName = window.localStorage.getItem(`game-playerName`);
    // get config based on playerName
    this.state.config = {};

    this.state.playerName = playerName;

  }

  async updateBoard() {
    console.log(`check updateBoard`);
    if (checkerGame) {
      const fen = checkerGame.getFen();
      const turn = checkerGame.getTurn();
      const turnCount = checkerGame.getTurnCount();
      this.setState({
        fen,
        turn,
        board: checkerGame.getBoardFull(),
        winner: checkerGame.getWinner(),
      });

      console.log({turnCount,turn,fen});
      await apiClient({
        method: 'POST',
        url: this.state.gameId,
        data: {
          fen,
          turnCount,
        }
      })
    }

  }

  //   async componentDidUpdate( prevProps,  prevState) {
  //     return this.loadGameOnUpdate();
  //   }


  renderSquare(square, i, j) {
    const key = `square-${i}-${j}`;
    // console.log({square,i,j});
    const {squareColor, piece} = square;
    const onClick = () => {
      console.log(`onClick`, squareColor)
      if (squareColor === 'w') {
        return;
      }
      if (this.state.clickedSquare) {
        checkerGame.moveFromTo(this.state.clickedSquare, [i,j]);
        // request move.
        this.setState({
          clickedSquare: null
        })
      } else {
        this.setState({
          clickedSquare: [i,j]
        });
      }
      // change state to is clicked at index.

      // checkerGame.clickPiece(i,j);
    };

    const clickedSquare = this.state.clickedSquare;

    let isClicked = false;
    if (clickedSquare) {
      if (clickedSquare[0] === i && clickedSquare[1] === j) {
        isClicked = true;
      }
    }


    let className = squareColor === 'b' ? 'black-square' : 'white-square';
    className += isClicked ? ` clicked-square` : ``
    let pieceEl = (<span></span>);
    if (piece === 'w') {
      pieceEl = (<span>w</span>);
    } else if (piece === 'b') {
      pieceEl = (<span>b</span>);
    }

    if (piece !== ' ') {
      pieceEl = (<span className={`piece-${piece}`}></span>);
    }
    // make square clickable if it has a piece.
    return (
      <td onClick={onClick} key={key} className={className}>
          {pieceEl}
      </td>
    );
  }


  renderRow(row, i) {

    // console.log(row,i);


    return (
      <tr key={`checkerboard-row-${i}`}>
          {row.map((square, j) => this.renderSquare(square,i,j))}
      </tr>
    );


  }

  async newBoard (el) {
    console.log(`newBoard`,el);

    // create new chess game
    const result = await apiClient({
      method: 'POST'
    });
    console.log(result.status,result.data);

    const id = result.data.id;

    // redirect(`/${id}`);
    window.location = (`${pathname}/${id}`);

    // redirect to this game.


    // await client
  }

  async componentDidMount( prevProps,  prevState) {
    return this.loadGameOnUpdate();
      
  }
  async componentDidUpdate( prevProps,  prevState) {
    return this.loadGameOnUpdate();
  }

    /**
   * Get the game state if it is not your turn.
   * TODO websocket.
   */
    async checkGameState() {
      const result = await apiClient.get(this.state.gameId);
      // console.log(`got game result`, {result});
  
      const {data: {fen, turnCount}} = result;
  
      if (turnCount && turnCount > checkerGame.getTurnCount()) {

        checkerGame.updateBoard(turnCount,fen, false);

        // const fen = checkerGame.getFen();
        // const turn = checkerGame.getTurn();
        this.setState({
          fen: checkerGame.getFen(),
          turn: checkerGame.getTurn(),
          board: checkerGame.getBoardFull(),
          winner: checkerGame.getWinner(),
        });
      }
    }

  async loadGameOnUpdate() {
    if (this.state.gameId && this.state.config.orientation && !checkerGame) {
            // https://chessboardjs.com/examples.html#5000
        // game = new Chess();
        // const gameId = this.state.gameId;


        // await this.doLoad();

        const result = await apiClient.get(this.state.gameId);
        console.log(`got game result`, {result});

        const {data: {fen, turnCount}} = result;        
        // const fen = '';
        // console.log(`componentDidMount`, this.state.gameId);

        // this.handleChessBoardMain(this.updateBoard.bind(this), fen, this.state.config);

        console.log('componentDidMount');
        const callback = () => {
          console.log(`callback`);
          this.updateBoard();
        }
        checkerGame = initCheckers(callback, fen, turnCount);
        this.updateBoard();

        this.setState({
          board: checkerGame.getBoardFull()
        });
        pingGameState = setInterval(() => {
           this.checkGameState();
        }, 1000 * 2);
    } else {

    }
  }

  render() {



    console.log(`CheckersAppMain`, this.state);
      if (!this.state.playerName) {
        // display login.
        return <GameLogin
        playerName={this.state.playerName}
        playerCallback={(player) => {
          console.log(`player name set to `, {player});
          this.setState({playerName: player.playerName});
          window.localStorage.setItem(`game-playerName`, player.playerName);
        }}
        ></GameLogin>
      } else {
        // wait for player to load. loader will set playerName to '' if not found.
      }
      // this.state.playerName
    // }
    if (!this.state.gameId) {
      return (
        <button onClick={(el) => {
          console.log(this,el, this.newBoard(el));
        }}>New game</button>
      )

    }






    // add and not loading config or have PickASide handle loading.
    if (!this.state.config.orientation) {

      return (
        <PickASide gameType={"checkers"} 
        gameId={this.state.gameId}
        playerName={this.state.playerName}
        setConfigCallback={(config) => {
          console.log(`config set to `,config);
          this.setState({
            ...this.state,
            config,
          });
        }}
        ></PickASide>
      )
    }

    const board = this.state.board;
    const winner = this.state.winner;

    if (!board) {
      return (<div></div>)
    }

    return (

      <div className={`checkers-app`}>
        <div>
          {this.state.turn === this.state.config.orientation ? 'Your turn' : 'Your Opponent\'s turn'}
        </div>
        <div>
          You are playing as {this.state.config.orientation === 'b' ? 'Black' : 'Red'}
        </div>
        {/* <div>
          <pre>{this.state.fen}</pre>
        </div> */}
        {winner && <div>{winner} wins</div>}
        <div>


                <table>
                  <tbody>
                    {this.state.board.map((row, i) => this.renderRow(row, i))}
          
            </tbody>
        </table>
        </div>
      </div>

    )
  }
}
