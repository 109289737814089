import React from 'react';
import MidiPlayer from './MidiPlayer';

let viz;

export default class Midi extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

        }

    }

    stop() {
        console.log(`stop`);
    }

    //https://magenta.github.io/magenta-js/music/classes/_core_player_.midiplayer.html
    run(note, t) {
        // endTime: 5
        // pitch: 65
        // program: 19
        // startTime: 4.5
        // console.log(note);

        console.log({ t });

        viz.redraw(note);

    }


    async componentDidMount() {

    }

    componentWillUnmount() {
    }


    playClick() {

    }

    stopClick() {
    }

    handleChange(event) {
        if (event.target.type === 'checkbox') {
            this.setState({ [event.target.name]: event.target.checked });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }



    render() {
        const state = this.state;

        // https://stackoverflow.com/questions/69542807/react-the-best-way-to-pass-html-attributes-as-props

        const midiProps = {
            'soundFont': true,
            src: "https://magenta.github.io/magenta-js/music/demos/melody.mid"
        };
        return (<div>
            {/* <midi-visualizer type="piano-roll" id="myVisualizer"></midi-visualizer> */}

            {/* sound-font visualizer="#myVisualizer" */}
            {/* https://stackoverflow.com/questions/37828543/how-to-pass-props-without-value-to-component */}


<MidiPlayer />
            {/* <midi-player */}
                {/* src="https://magenta.github.io/magenta-js/music/demos/melody.mid" */}
                {/* soundfont visualizer="#myVisualizer"> */}
            {/* </midi-player> */}
            {/* <midi-visualizer type="piano-roll" id="myVisualizer"></midi-visualizer> */}
            {/* <midi-visualizer type="piano-roll" id="myVisualizer"></midi-visualizer> */}

        </div>);
    }
}