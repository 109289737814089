import oscillators from "web-audio-oscillators";


// https://arxiv.org/ftp/arxiv/papers/1211/1211.4832.pdf

export default class Synth {


    constructor(audioContext, instrumentName) {
        this.oscillatorGainNodes = {

        };
        this.instrumentName = instrumentName;
        this.audioContext = audioContext;
        this.decayTime = 0.1;


        // this.oscillator = oscillator;
        // this.gainNode = gainNode;

    }

    getGainOscillatorNodes(freq) {
        const name = this.instrumentName;
        let key = `${name}-${freq}`;

        if (!this.oscillatorGainNodes[key]) {
            console.log(`Synth creating oscillator`);
            const audioContext = this.audioContext;
            const oscillator = oscillators[name](this.audioContext);

            const time = audioContext.currentTime;
            oscillator.frequency.setValueAtTime(0, time); // always start at 0

            let gainNode = audioContext.createGain();
            gainNode.connect(this.audioContext.destination);
            gainNode.gain.setTargetAtTime(0, time , 0);
            oscillator.connect(gainNode);
            oscillator.start();
            this.oscillatorGainNodes[key] = {
                oscillator,
                gainNode
            }
        }
        return this.oscillatorGainNodes[key];

    }


    playStop(freq) {
        const {audioContext: audioCtx} = this;
        // this.oscillator.stop();
        const {oscillator,gainNode} = this.getGainOscillatorNodes(freq);

        const time = audioCtx.currentTime;
        // const gainNode = this.gainNode;
        gainNode.gain.setTargetAtTime(0, time + this.decayTime , 0);
    }

    /**
     * 
     * @param {*} freq 
     * @returns 
     */
    playStart(freq = 400) {
        const {audioContext} = this;
        const time = audioContext.currentTime;

        const {oscillator,gainNode} = this.getGainOscillatorNodes(freq);
        // const {audioContext: audioCtx, oscillator, gainNode} = this;
        let maxVolume = .05;
        oscillator.frequency.setValueAtTime(freq, time); // value in hertz
        gainNode.gain.setTargetAtTime(maxVolume, time , 0);
        // console.log(`playStart`, freq, oscillator, gainNode);
    }
}